import React from "react";
import CardStack from "../CardStack";
import { GENRES, AGE_GROUPS, TYPES } from "../../constants/Metadata";
import SearchBar from "../SearchBar";

//TODO: Convert to use genres and age groups from database
const Search = () => {
  return (
    <section className="animated fadeIn">
      <div className="container-fluid px-5">

        <div className="mb-8">
          <SearchBar />
        </div>

        <div className="w-100 mb-5">
          <h2 className="text-purple">Browse by Type</h2>
          <CardStack
            set={TYPES.map(seriesType => ({
              title: seriesType.label,
              id: seriesType.id,
              icon: seriesType.icon,
              backgroundColor: seriesType.bgColor || '#2F1782'
            }))}
            browseBy="type"
          />
        </div>

        <div className="w-100 mb-5">
          <h2 className="text-purple">Browse by Age Group</h2>
          <CardStack
            set={AGE_GROUPS.filter(item => item.id !== "none-specified").map(ageGroup => ({
              title: ageGroup.label,
              subtitle: ageGroup.subLabel,
              id: ageGroup.id,
              icon: ageGroup.icon,
              backgroundColor: ageGroup.bgColor || '#2F1782'
            }))}
            browseBy="age-group"
            formatAgeGroup
          />
        </div>

        <div className="w-100">
          <h2 className="text-purple">Browse by Genre</h2>
          <CardStack
            set={GENRES.map(genre => ({
              title: genre.label,
              id: genre.id,
              icon: genre.icon,
              backgroundColor: genre.bgColor || '#3F729B'
            }))}
            browseBy="genre"
          />
        </div>
      </div>
    </section>
  );
};

export default Search;
