import React, { useState, createContext } from "react";

const MobilePlayerContext = createContext([{}, () => {}]);

const MobilePlayerProvider = props => {
  const [state, setState] = useState({
    fullPlayerVisibility: false
  });

  return (
    <MobilePlayerContext.Provider value={[state, setState]}>
      {props.children}
    </MobilePlayerContext.Provider>
  );
};

export { MobilePlayerContext, MobilePlayerProvider };
