import React from "react";
import { CollectionContext } from "../../providers/CollectionProvider";
import CollectionCard from "./CollectionCard";
// import FavoriteSeriesCard from "./FavoriteSeriesCard";

// import SeriesIcon from '../../assets/icons/SeriesIcon.png';
import StorierToken from '../../assets/icons/StorierToken.png';

const StoryCollection = () => {
  const collection = React.useContext(CollectionContext);
  // const favorites = React.useContext(FavoriteSeriesContext);

  return (
    <div className="container-fluid animated fadeIn">
      <header className="space-top-1 space-bottom-2">
        <div className="text-center">
          <div className="mb-5">
            <img src={StorierToken}
              alt="My Story Collection"
              style={{ height: `6rem` }}
            />
          </div>
          <h1 className="display-4 heavy-font">Story Collection</h1>
          <p className="lead text-muted mt-3">Collections you have purchased</p>
        </div>
      </header>
      <article>
        <div className="row compact-row">
          {collection.map(series => (
            <CollectionCard key={series.id} {...series} />
          ))}
        </div>
      </article>
    </div>
  );
};

export default StoryCollection;
