import React, { useState, useEffect, createContext } from "react";
import { auth, getUserDocument, analytics } from "../firebase";
import { removeSearchKey } from "../utilities";

export const UserContext = createContext({ user: null });

/**
 * Authorization problems/troubleshoot:
 * https://github.com/psatler/react-firebase-authentication/tree/master/src/components
 * https://github.com/CSFrequency/react-firebase-hooks
 */
const UserProvider = props => {
  const [{ user }, setUser] = useState({ user: null });

  useEffect(() => {
    const unsubscribe = () => {};

    auth.onAuthStateChanged(async user => {
      removeSearchKey();
      if (user) {
        const userRef = await getUserDocument(user.uid);
        userRef.onSnapshot(snapshot => {
          setUser({ user: { uid: snapshot.id, ...snapshot.data() } });
          analytics.setUserId(snapshot.id);
        });
      }
    });
    
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
