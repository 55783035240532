import React, { useContext } from "react";
import AlbumInfo from "./PlayerAlbumInfo";
import Controls from "./PlayerControls";
import SecondaryControls from "./PlayerSecondaryControls";
import TrackPogressBar from "./PlayerProgressBar";
import { convertHMS, progressBarTime } from "../../utilities";
import { PlayerContext } from "../../providers/PlayerProvider";

const PlayerBar = () => {

  const { duration, currentTime } = useContext(PlayerContext);

  return (
    <section
      id="player-bar"
      className="border-top bg-indigo text-white d-flex align-items-center"
    >
      <div className="container-fluid p-0">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-4 col-lg-3">
            <AlbumInfo />
          </div>
          <div className="col-md-4 col-lg-6 align-self-start playerbar_controls_desktop">
            <div className="mb-1 mt-1">
              <Controls />
            </div>
            <div
              className="mt-2 pt-1 px-lg-8 px-md-0"
              style={{ position: "absolute", width: "100%" }}
            >
              <TrackPogressBar height={8} id="desktopTrackBar" />
              <div
                className="pt-1 d-flex justify-content-between small text-info"
                style={{
                  position: "relative",
                  top: "-32px",
                  width: "100%",
                  zIndex: "-1"
                }}
              >
                <span>{progressBarTime(convertHMS(~~currentTime))}</span>
                <span>
                  {duration
                    ? progressBarTime(convertHMS(duration))
                    : "00:00:00"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 playerbar_secondarycontrols_desktop">
            <SecondaryControls />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlayerBar;
