import React, { useContext } from "react";
import { convertHMS, progressBarTime, timeLeft } from "../../utilities";
import PlayerProgressBar from "../PlayerBar/PlayerProgressBar";
import { PlayerContext } from "../../providers/PlayerProvider";

const MobilePlayerProgress = () => {
  const {duration, currentTime} = useContext(PlayerContext);

  return (
    <div className="px-4 my-3">
      <PlayerProgressBar id="mobileTrackBar" />

      <div className="pt-1 d-flex justify-content-between small text-success">
        <span>{progressBarTime(convertHMS(~~currentTime))}</span>
        <span>
          {duration ? progressBarTime(convertHMS(duration)) : "00:00:00"}
        </span>
      </div>

      <div className="text-center text-warning py-3">
        {timeLeft(convertHMS(~~duration - ~~currentTime))} left
      </div>
    </div>
  );
};

export default MobilePlayerProgress;
