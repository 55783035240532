import React from "react";
import { Link } from "react-router-dom";
import { LOST_EXPLORER } from "../../constants/Metadata";


const PageNotFound = () => {
  return (
    <div
      className="container-fluid d-flex align-items-center justify-content-center"
      style={{ minHeight: "80vh" }}
    >
      <div>
        <div className="mx-auto mb-4" style={{ maxWidth: 700 }}>
          <img src={LOST_EXPLORER} alt="" className="w-100" />
        </div>
        <h1 className="text-center h2">Sorry, that page could not be found.</h1>
        <p className="text-center">
          Please check your URL or try our <Link to="/search">search page</Link>
          , or <Link to="/NO-LINK">reach out to us</Link>.
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
