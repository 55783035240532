import React from "react";

/**
 * Solution found here:
 * https://stackoverflow.com/questions/55757761/handle-an-input-with-react-hooks
 */
function useInput({ type, name, placeholder }) {
  const [value, setValue] = React.useState("");
  const input = (
    <input
      className="form-control"
      value={value}
      name={name}
      onChange={event => setValue(event.target.value)}
      type={type}
      placeholder={placeholder}
    />
  );
  return [value, input];
}

export default useInput;
