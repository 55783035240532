import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { PlaylistsContext } from "../../providers/PlaylistsProvider";
import firebase, { analytics, firestore } from "../../firebase";
import { toast } from "react-toastify";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HEADPHONES } from "../../constants/Metadata";


const AddToPlaylist = ({ id, minimal }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const playlists = useContext(PlaylistsContext);

  const handleSelect = playlistId => {
    firestore
      .collection('playlists')
      .doc(playlistId)
      .update({
        tracks: firebase.firestore.FieldValue.arrayUnion(id)
      })
      .then(() => {
        analytics.logEvent('playlist_track_added', {
          trackId: id
        });
        setModalShow(false);
        toast("🎉 Added to playlist!");
      });
  };

  return (
    <>
      <button
        onClick={() => setModalShow(true)}
        title="Add this track to a playlist"
        type="button"
        className={`no-style no-padding has-svg d-md-inline-block ${minimal && 'text-white'}`}
      >
        {minimal ? <FontAwesomeIcon icon={faPlusCircle} /> : 'Add to Playlist'}
      </button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="new-playlist-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="new-playlist-modal">Select a playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row compact-row">
            {playlists.map(playlist => (
              <div className="col-12 col-lg-6 mb-3" key={playlist.id}>
                <button
                  onClick={() => handleSelect(playlist.id)}
                  className="btn btn-lg w-100 text-left bg-indigo text-white font-size-2 rounded"
                >
                  <img
                    src={HEADPHONES}
                    style={{ height: `2.2rem`, display: `inline-block` }}
                    className="mr-2"
                    alt="Playlist Icon"
                  />
                  {playlist.playlistTitle}
                </button>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddToPlaylist;
