import React, { useState, useEffect, useContext } from "react";
import algoliasearch from 'algoliasearch/lite';

import PageHeader from "../PageHeader";
import { AGE_GROUPS, GENRES, TYPES } from "../../constants/Metadata";
import SeriesCard from "../Series/SeriesCard";
import { getSearchKey } from "../../utilities";
import { UserContext } from "../../providers/UserProvider";

const Query = props => {
  const { collection, category } = props.match.params;
  const [results, setResults] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [searchIndex, setSearchIndex] = useState(null);
  const user = useContext(UserContext);

  const [headerInfo, setHeaderInfo] = useState({
    title: "Title",
    svg: {}
  });

  useEffect(
    function setHeader() {
      if (collection === "age-group") {
        const ageGroup = AGE_GROUPS.filter(a => a.id === category)[0];
        if (ageGroup)
          setHeaderInfo({ title: ageGroup.label, svg: ageGroup.icon });
      }
      if (collection === "genre") {
        const genre = GENRES.filter(a => a.id === category)[0];
        if (genre) setHeaderInfo({ title: genre.label, svg: genre.icon });
      }
      if (collection === "type") {
        const type = TYPES.filter(a => a.id === category)[0];
        if (type) setHeaderInfo({ title: type.label, svg: type.icon });
      }
    },
    [category, collection]
  );

  useEffect(() => {
    getSearchKey()
      .then(result => {
        if (result) {
          const client = algoliasearch(window.ALGOLIA_APP_ID, result);
          const index = client.initIndex('series');
          setSearchIndex(index);
        } else {
          setSearchError(true);
        }
      });
  }, []);

  useEffect(() => {
    if (!user || ! searchIndex) {
        return;
      }

    let whereClause = collection;

    if (collection === 'genre') {
      whereClause = "storier.genre";
    } else if (collection === 'age-group') {
      whereClause = "storier.age-group";
    }

    searchIndex
      .search('', {filters: `${whereClause}:${category}`})
      .then(({ hits }) => {
        setResults(hits);
    });
  }, [category, collection, user, searchIndex]);

  return (
    <section className="animated fadeIn">
      <div className="container-fluid">
        <PageHeader title={headerInfo.title} svg={headerInfo.svg} />
        {searchError}
        <div className="row cardWrapper">
          {results.map(item => (
            <SeriesCard
              key={item.id}
              className="homeCard"
              {...item}
            />
          ))}
        </div>
        <div className="text-center">
          More coming soon
        </div>
      </div>
    </section>
  );
};

export default Query;
