import { useContext } from "react";
import { MobilePlayerContext } from "../providers/MobilePlayerContext";

const useMobilePlayer = () => {
  const [state, setState] = useContext(MobilePlayerContext);

  function toggleVisibility() {
    setState({
      fullPlayerVisibility: !state.fullPlayerVisibility
    });
  }

  return {
    toggleVisibility,
    fullPlayerVisibility: state.fullPlayerVisibility
  };
};

export default useMobilePlayer;
