import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';
import { useDebounce } from 'use-lodash-debounce'

import { getSearchKey } from '../utilities';
import SeriesCard from "./Series/SeriesCard";

const SearchBar = () => {
  const [searchError, setSearchError] = useState(false);
  const [searchIndex, setSearchIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showMessageNoResults, setShowMessageNoResults] = useState(false);

  const updateSearch = (term) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      setShowResults(false);
      setShowMessageNoResults(false);
    }

    searchIndex.search(term).then(({ hits }) => {
      setSearchResults(hits);
      setShowMessageNoResults(hits.length < 1);
      setShowResults(true);
    });
  };


  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    getSearchKey()
      .then(result => {
        if (result) {
          const client = algoliasearch(window.ALGOLIA_APP_ID, result);
          const index = client.initIndex('series');
          setSearchIndex(index);
        } else {
          setSearchError(true);
        }
      });
  }, []);

  useEffect(() => {
    if (searchIndex && debouncedSearchTerm.length > 1) {
      updateSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, searchIndex]); //eslint-disable-line

  return (
    <>
      <div className="mb-5">
        <div className="input-group input-group-lg">
          <label className="sr-only" htmlFor="searchbox">
            Search property
          </label>
          <input
            type="text"
            className="form-control rounded"
            name="searchbox"
            id="searchbox"
            placeholder="🔍 Search for Titles, Subjects, and more!"
            aria-label="Search for Titles, Subjects, and more!"
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
        {searchError && <p className="text-danger">
          Error Searching!
        </p>}
      </div>
      <div id="results" className={`w-100 ${showResults ? "" : "d-none"}`}>
        <h2>Results</h2>
        <div
          className={`animated fadeIn alert alert-soft-primary text-center py-10 ${
            showMessageNoResults ? "" : "d-none"
          }`}
        >
          <h3 className="mb-0 font-weight-normal">
            Sorry! No titles seem to match... try again?
          </h3>
        </div>
        <div className="row">

          {searchResults.map(item => (
            <SeriesCard
              key={item.id}
              {...item}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
