import React, { useState, useEffect, useContext } from "react";
import { firestore } from "../../firebase";
import { collectIdsAndDocs } from "../../utilities";
import Spinner from "../Spinner";
import SomethingWentWrong from "../views/SomethingWentWrong";
import SeriesHeader from "./SeriesHeader";
import TrackList from "../Tracks/TrackList";
import { UserContext } from "../../providers/UserProvider";

/**
 * Fetches a podcast, displays it to view
 */
const Series = props => {
  const user = useContext(UserContext);
  const { seriesId } = props.match.params;

  const [series, setSeries] = useState({});
  const [tracks, setTracks] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPurchased, setIsPurchased] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const seriesRaw = await firestore
          .collection('series')
          .doc(seriesId)
          .get();

        const currentSeries = collectIdsAndDocs(seriesRaw);
        setSeries(currentSeries);

        if (!seriesRaw.exists) {
          setErrorMessage('This content is not available anymore or not available in your region.')
        }

        const trackPromises = (currentSeries.tracks || []).map(trackId => {
          return firestore
            .collection('tracks')
            .doc(trackId)
            .get();
        });

        const trackDocs = await Promise.all(trackPromises);

        let tracks = trackDocs.map(collectIdsAndDocs);
        if (currentSeries.type === 'podcast') {
          tracks = tracks.reverse();
        }

        setTracks(tracks);

        if (currentSeries.isPremium && user) {
          firestore
            .collection('users')
            .doc(user.uid)
            .collection('purchased')
            .doc(seriesId)
            .onSnapshot(purchasedSeries => {
              if (purchasedSeries.exists) {
                setIsPurchased(true)
              } else {
                setIsPurchased(false);
              }
            });
        }

        setFetching(false);
      } catch (error) {
        console.error(error);
        setErrorMessage('Please try again later.');
      }
    };

    getData();
  }, [seriesId, user]);

  return (
    <>
      {errorMessage ? null :
        <Spinner fetching={fetching}>
          <div className="animated fadeIn px-lg-8 px-md-4 pb-10 container-fluid row">
            <div className="col-xl-6 col-lg-12">
              <SeriesHeader {...series} isPurchased={isPurchased} />
            </div>
            <div className="col-xl-6 pt-xl-6 col-lg-12">
              {
                (!series.isPremium || isPurchased) &&
                <article className="css-no-padding-below-md">
                  <TrackList
                    draggable={false}
                    tracks={tracks}
                  />
                </article>
              }
            </div>
          </div>
        </Spinner>
      }
      <SomethingWentWrong errorMessage={errorMessage} />
    </>
  );
};

export default Series;
