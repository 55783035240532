import React from "react";
import { analytics, firestore } from "../../firebase";
import { toast } from "react-toastify";
import firebase from "../../firebase";

const RemoveFromPlaylist = ({ id, playlistId }) => {
  const handleRemove = () => {
    firestore
      .collection("playlists")
      .doc(playlistId)
      .update({
        tracks: firebase.firestore.FieldValue.arrayRemove(id)
      })
      .then(() => {
        analytics.logEvent('playlist_track_removed', {
          trackId: id
        });
        toast("⚡ Removed track from playlist.");
      })
      .catch(error => console.error(error));
  };

  return (
    <button
      onClick={() => handleRemove()}
      title="Remove track from playlist"
      type="button"
      className="no-style no-padding"
    >
      Remove track from playlist
    </button>
  );
};

export default RemoveFromPlaylist;
