import React, { useState, useContext } from 'react';
import { UserContext } from "../../providers/UserProvider";
import { trackPromise } from 'react-promise-tracker';

// Bootstrap
import Modal from 'react-bootstrap/Modal';

// Firebase
import { functions } from '../../firebase';

// Components
import TokenIcon from './TokenIcon';

// Asests
import StorierToken from '../../assets/icons/StorierToken.png';
import EmptyBank from '../../assets/icons/EmptyBank.png';

import './UseTokens.scss';

const UseTokens = ({ price, seriesId }) => {
	const user = useContext(UserContext);
	const [showModal, setShowModal] = useState(false);


	const handlePurchase = async () => {
		const purchaseSeries = functions.httpsCallable('purchaseSeries');

		setShowModal(false);

		trackPromise(
			purchaseSeries({
				seriesId: seriesId
			}).then(result => {
				if (!result.data.success) {
					alert('Error purchasing story. Please contact Storier for help.');
				}
			})
		);
	}

	const daysTillToken = () => {
		if (!user.private || !user.private.lastMonthTokenAllocation) return;

		const now = new Date(new Date().toDateString());
		const lastTokenAllocation = new Date(user.private.lastMonthTokenAllocation.toDate().toDateString());

		let daysTillNextToken = 30 - ((now.getTime() - lastTokenAllocation.getTime()) / (1000 * 3600 * 24));

		return daysTillNextToken;
	}

	const amountOfTokens = () => {
		if (!user.private || !user.private.subscription) return;

		if (user.private.subscription === 'EXTRA') {
			return 4;
		} else if (user.private.subscription === 'SUPER') {
			return 6;
		}
	}


	const insufficientBody = () => {
		return (
			<>
				<div className="hdr-image">
					<img
						className="empty-bank-img"
						src={EmptyBank}
						alt="empty bank"
					/>
				</div>

				<div className="text-container">
					<div className="d-flex justify-content-center align-items-center text-bold">
						You have
						<TokenIcon className="mx-2" value={user.private.tokens || 0} width={40} />
						Storier Tokens
					</div>
					<div>
						<span className="days-left-text">
							{daysTillToken()} {daysTillToken() === 1 ? "Day" : "Days"}{" "}
						</span>
						until your next {amountOfTokens()} Storier Tokens
					</div>
				</div>

				<div className='btn-container'>
					<button
						className="btn btn-pill btn-ok"
						onClick={() => setShowModal(false)}
					>
						OK
					</button>
				</div>
			</>
		)
	}

	const confirmBody = () => {
		return (
			<>
				<div className="hdr-image">
					<img
						className="storier-token-img"
						src={StorierToken}
						alt="Storier Token"
					/>
				</div>

				<div className="use-tokens-text-container">
					Are you sure you want to use
					<TokenIcon value={price} width={50} />
					Storier Tokens?
				</div>

				<div className="d-flex justify-content-center">
					<button
						className="btn btn-pill btn-cancel"
						onClick={() => setShowModal(false)}
					>
						Cancel
					</button>
					<button
						className="btn btn-pill btn-ok"
						onClick={handlePurchase}
					>
						OK
					</button>
				</div>
			</>
		)
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<button
					type="button"
					className="btn btn-pill btn-use-tokens"
					onClick={() => setShowModal(true)}
				>
					Use tokens
				</button>
				<TokenIcon width={50} value={price} />
			</div>

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				contentClassName="use-tokens-modal"
			>
				{user.private.tokens >= price ? confirmBody() : insufficientBody()}
			</Modal>
		</>
	)
}

export default UseTokens;