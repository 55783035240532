import React from "react";
import AddToPlaylist from "../Playlists/AddToPlaylist";
import RemoveFromPlaylist from "../Playlists/RemoveFromPlaylist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { faEllipsisV as menu } from "@fortawesome/free-solid-svg-icons";
import ReportTrack from "../ReportTrack";

const TrackDropdown = props => {
  const {
    id,
    playlistId,
  } = props;

  return (
    <div id="trackDropDown">
      <Dropdown
        alignRight
        drop="up"
      >
        <Dropdown.Toggle
          className="no-style no-padding with-icon svgButton text-body"
          size="sm"
          id="dropdown-basic"
        >
          <FontAwesomeIcon icon={menu} size="lg" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="border">
          <Dropdown.Item>
            <AddToPlaylist id={id} />
          </Dropdown.Item>
          <Dropdown.Item>
            <ReportTrack id={id} />
          </Dropdown.Item>

          {playlistId && (
            <Dropdown.Item>
              <RemoveFromPlaylist id={id} playlistId={playlistId} />
            </Dropdown.Item>
          )}

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TrackDropdown;
