import React from "react";
import FavoriteTrackIcon from "./FavoriteTrackIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faArrowsAltV
} from "@fortawesome/free-solid-svg-icons";
import TrackDropdown from "./TrackDropdown";
import Moment from "react-moment";
import Accordion from "react-bootstrap/Accordion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import prettyTime from "pretty-time";
import PlayTrackButton from "./PlayTrackButton";

const Track = props => {

  const renderTooltipInfo = props => <Tooltip {...props}>More info</Tooltip>;

  const renderTooltipReorder = props => (
    <Tooltip {...props}>Click and drag to reorder</Tooltip>
  );

  const {
    id, title, pubDate, duration, summary,
    playlistId, draggable, draggableProps, seriesId,
    // subtitle
  } = props;

  const getFormattedDuration = () => {
    if (duration) {
      return prettyTime([duration, 795428088], 'm');
    }
  };

  let standardPubDate = pubDate;
  if (typeof pubDate === "object") {
    standardPubDate = new Date(standardPubDate.seconds * 1000).toISOString();
  }

  const collection = playlistId ? "playlists" : "series";

  return (
    <Accordion className="css-track-item border animated fadeIn">
      <div className="p-3">
        <div className="d-flex align-items-center pr-1">

          <FavoriteTrackIcon id={id} />

          <PlayTrackButton id={id} collection={collection} collectionId={playlistId || seriesId} />

          <div
            className="px-3 w-90"
            tabIndex={0}
            role="button"
          >
            <span className="d-block">
              <span style={{width: "calc(100% - 25px)", display: "inline-block"}}>{title}</span>

              <OverlayTrigger
                placement="right"
                delay={{ show: 250 }}
                overlay={renderTooltipInfo}
              >
                <Accordion.Toggle
                  type="button"
                  eventKey={0}
                  className="ml-2 no-style no-padding d-none has-svg css-track-accordian-toggle text-info"
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Accordion.Toggle>
              </OverlayTrigger>
            </span>

            <small className="text-muted" style={{ opacity: 0.9 }}>
              {standardPubDate && <Moment format="MMMM D, YYYY">{standardPubDate}</Moment>}
            </small>
          </div>

          <div className="ml-2 mr-1 d-none d-md-flex mono-time">
            {getFormattedDuration()}
          </div>

          <div className="ml-3 d-flex align-items-center track-buttons">
            <TrackDropdown id={id} playlistId={playlistId} />
          </div>

          {draggable && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250 }}
              overlay={renderTooltipReorder}
            >
              <div
                className="ml-3 d-flex has-svg align-items-center"
                {...draggableProps}
              >
                <FontAwesomeIcon icon={faArrowsAltV} size="lg" />
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>

      <Accordion.Collapse eventKey={0}>
        <div
          className="p-4 bg-light border-top"
          style={{ cursor: "pointer" }}
        >
          {/*<div>{subtitle}</div>*/}
          <div dangerouslySetInnerHTML={{ __html: summary }} />
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default Track;
