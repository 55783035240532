import React, { useContext } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { PlayerContext } from "../../providers/PlayerProvider";
import { SPEEDOMETER } from "../../constants/Metadata";

const SPEEDS = [0.5, 0.75, 1.0, 1.25, 1.5];

const SpeedControl = ({ smallIcon }) => {
  const [speedIndex, setSpeedIndex] = React.useState(2);
  const { playbackRate, changePlaybackRate } = useContext(PlayerContext);

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Change player speed
      </Tooltip>
    );
  }

  const handleClick = () => {
    let idx = speedIndex + 1;
    if (idx > SPEEDS.length - 1) {
      idx = 0;
    }
    setSpeedIndex(idx);
    return changePlaybackRate(SPEEDS[idx]);
  };

  return (
    <div id="track-speed">
      <OverlayTrigger
        placement="right"
        delay={{ show: 250 }}
        overlay={renderTooltip}
      >
        <button
          onClick={handleClick}
          className="btn no-padding text-center text-white"
        >
          <img
            src={SPEEDOMETER}
            alt="Speed Icon"
            style={{ width: smallIcon ? "1.2rem" : "2rem" }}
          />
          <span className="d-block">{playbackRate}x</span>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default SpeedControl;
