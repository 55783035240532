import React from "react";
import ToastDefault from "../Toastr.Notifications";

const ContentPanel = props => {
  return (
    <>
      <ToastDefault />
      <div id="content-panel">{props.children}</div>
    </>
  );
};

export default ContentPanel;
