import React, { useContext } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { PlayerContext } from "../../providers/PlayerProvider";

/**
 * Progress bar for player. Touchable.
 *    Maybe this should have been a range finder?
 *       It's too late now.
 *
 * @param {*} id - Needs a unique ID in DOM to be properly created
 * @param {*} height - Height of progress bar
 */
const PlayerProgressBar = ({ id, height }) => {

  const {duration, currentTime, changeCurrentTime} = useContext(PlayerContext);

  /**
   * Where in the window the event occured
   * @param {} e - the Event
   */
  function getStaticPosition(e) {
    return {
      x: e.clientX,
      y: e.clientY
    };
  }

  /**
   * As far as I can tell this gets the (0, 0) of an element.
   * @param {} el - The DOM element
   */
  function getPosition(el) {
    var xPosition = 0;
    var yPosition = 0;

    while (el) {
      if (el.tagName === "BODY") {
        // deal with browser quirks with body/window/document and page scroll
        var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
        var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

        xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
        yPosition += el.offsetTop - yScrollPos + el.clientTop;
      } else {
        xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPosition,
      y: yPosition
    };
  }

  /**
   * Calculates NEWTIME from event position
   * @param {*} event
   * @param {*} durationInSeconds - Duration of audio
   */
  function calcs(event, durationInSeconds) {
    var el = document.getElementById(id);
    var zeroX = getPosition(el).x;
    var eventX = getStaticPosition(event).x;
    var widthX = el.offsetWidth;
    var diffX = eventX - zeroX;
    var newTime = (diffX * durationInSeconds) / widthX;

    return newTime;
  }

  function handleMouseUp(e) {
    const newTime = calcs(e, duration);
    changeCurrentTime(newTime);
  }

  return (
    <ProgressBar
      id={id}
      style={{ height: height ? height : 15 }}
      max={duration}
      now={currentTime}
      variant="success"
      onMouseUp={handleMouseUp}
    />
  );
};

export default PlayerProgressBar;
