import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { PlayerContext } from "../../providers/PlayerProvider";


const PlayTrackButton = ({ id, collection, collectionId }) => {
  const { activeTrack, isPlaying, playTracks, togglePlay } = useContext(PlayerContext);

  const thisTrackActive = activeTrack && activeTrack.id === id;

  const handleClick = () => {
    if (thisTrackActive) {
      togglePlay();
    } else {
      playTracks(collectionId, collection, id)
    }
  };

  return (
    <button className="no-style has-svg" onClick={handleClick} >

      <FontAwesomeIcon
        icon={thisTrackActive && isPlaying ? faPause : faPlay}
        size="lg"
        color="#42B0D2"
      />
    </button>
  )
};

export default PlayTrackButton;
