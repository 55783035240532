import React, { useState, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";
import { collectIdsAndDocs } from "../utilities";

const PlaylistsContext = React.createContext({
  playlists: null
});

const PlaylistsProvider = props => {
  const user = useContext(UserContext);

  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection(`playlists`)
        .where("userId", "==", user.uid)
        .where("type", "==", "standard")
        .onSnapshot(snapshot => {
          const playlists = snapshot.docs.map(collectIdsAndDocs);
          setPlaylists(playlists);
        });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <PlaylistsContext.Provider value={playlists}>
      {props.children}
    </PlaylistsContext.Provider>
  );
};

export { PlaylistsContext, PlaylistsProvider };
