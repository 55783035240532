import { useContext } from "react";
import { firestore, increment } from "../firebase";
import { UserContext } from "../providers/UserProvider";

const useListenHistory = () => {
  const user = useContext(UserContext);

  async function addToHistory(collectionId, collection) {
    firestore
      .collection("users")
      .doc(user.uid)
      .collection("listenHistory")
      .add({ collectionId, collection, addedAt: new Date() });
  }

  async function addToTrackHistory(trackId) {
    //TODO: batch these together for atomic write
    await firestore
      .collection("users")
      .doc(user.uid)
      .collection("trackHistory")
      .add({
        trackId,
        player: 'web',
        addedAt: new Date()
      });

    await firestore
      .collection("users")
      .doc(user.uid)
      .update({
        trackHistoryCount: increment
      })
  }

  return {
    addToHistory,
    addToTrackHistory
  };
};

export default useListenHistory;
