import React from "react";
import { Link } from "react-router-dom";

const Card = props => {
  const {
    id,
    browseBy,
    title,
    subtitle,
    backgroundColor,
    icon
  } = props;

  function createUrl() {
    if (browseBy) return `browse/${browseBy}/${id}`;
  }

  return (
    <div
      className={`animated fadeIn col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 mb-3`}
      style={{ padding: "0px 10px" }}
    >
      <Link to={createUrl}>
        <div
          className={`rounded shadow p-2 text-center d-flex flex-column justify-content-center align-items-center ${!backgroundColor &&
          "bg-secondary"}
          } text-white`}
          style={{ height: 200, backgroundColor: backgroundColor }}
        >
          {icon && (
            <div className="pb-3">
              <img src={icon} alt={title} style={{ width: `6rem` }} />
            </div>
          )}
          <div style={{ lineHeight: 1.3 }}>
            <span className="font-weight-bold d-block">{title}</span>
            {subtitle && (
              <small className="font-weight-normal my-0">{subtitle}</small>
            )}
          </div>
        </div>


      </Link>
    </div>
  );
};

export default Card;
