import React from "react";
import { Link } from "react-router-dom";

const IconCard = props => {
  const { label, subLabel, className, linkTo, svg, bgColor } = props;

  return (
    <div
      className={`iconCard animated fadeIn col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2`}
    >
      <Link to={linkTo}>
        <div
          className={`coverArt shadow-sm d-flex justify-content-center align-items-center p-2`}
          style={{
            backgroundColor: bgColor || '#2F1782'
          }}
        >
          <div className={`text-center text-white ${className}`}>
            <div className="pb-2">
              <img src={svg} alt="label" style={{ height: `5.5rem` }} />
            </div>
            <div>
              <span className="cardLabel d-block font-weight-bold add-text-shadow">
                {label}
              </span>
              <span className="cardSublabel d-block text-white" style={{ opacity: 0.8 }}>
                {subLabel}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default IconCard;
