import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { collectIdsAndDocs } from "../../utilities";
import Spinner from "../Spinner";
import SomethingWentWrong from "../views/SomethingWentWrong";
import PlaylistHeader from "./PlaylistHeader";
import TrackList from "../Tracks/TrackList";
import { UserContext } from "../../providers/UserProvider";

const Playlist = props => {
  const user = useContext(UserContext);
  const { playlistId } = props.match.params;

  const [fetching, setFetching] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [playlist, setPlaylist] = useState({});
  const [tracks, setTracks] = useState([]);
  const [derivedPlaylistId, setDerivedPlaylistId] = useState(null);

  async function getTrackDocument(trackId) {
    return await firestore
      .collection("tracks")
      .doc(trackId)
      .get();
  }

  useEffect(() => {
    let curPlaylistId = '';

    if (!playlistId && !user) {
      return;
    } else if (!playlistId && !user.favoriteTracksId) {
      setFetching(false);
      return;
    } else if (!playlistId) {
      curPlaylistId = user.favoriteTracksId;
      setDerivedPlaylistId(user.favoriteTracksId);
    } else {
      curPlaylistId = playlistId;
      setDerivedPlaylistId(playlistId);
    }

    firestore
      .collection('playlists')
      .doc(curPlaylistId)
      .get()
      .then(async doc => {
        if (!doc.exists) {
          throw Error("doesnt exist");
        }

        const curPlaylist = collectIdsAndDocs(doc);
        const trackPromises = curPlaylist.tracks.map(trackId => getTrackDocument(trackId));
        const curTracksRaw = await Promise.all(trackPromises);
        const curTracks = curTracksRaw.map(collectIdsAndDocs);

        setPlaylist(curPlaylist);
        setTracks(curTracks);
        setFetching(false);
      })
      .catch(error => {
        console.log("error getting doc", error);
        setErrorMessage("Please try again later.");
        setFetching(false);
      });
  }, [playlistId, derivedPlaylistId, user]);

  const onReordered = async (reorderedTracks) => {
    await firestore
      .collection("playlists")
      .doc(derivedPlaylistId)
      .update({ tracks: reorderedTracks.map(item => item.id) });

    setTracks(reorderedTracks);
  };


  const emptyMessage = () => {
    if (!fetching && tracks.length === 0) {
      return (
        <div
          className="text-center rounded-lg px-3 py-10"
          style={{ fontSize: "140%", opacity: 0.7 }}
        >
          <span role="img" aria-label="Wizard Emoji">
            🧙‍♂️
          </span>{" "}
          This playlist is empty and waiting for tracks!{" "}
          <span role="img" aria-label="Fairy Emoji">
            🧚‍♀️
          </span>
        </div>
      )
    } else {
      return null;
    }
  }


  return (
    <>
      <Spinner fetching={fetching}>
        <div className="animated fadeIn px-lg-8 px-md-4 pb-10 container-fluid" >
          <PlaylistHeader {...playlist} tracks={tracks} />
          <article className="css-no-padding-below-md">
            <TrackList
              draggable={true}
              tracks={tracks}
              onReordered={onReordered}
              playlistId={derivedPlaylistId}
            />
          </article>
        </div>
        {emptyMessage()}
      </Spinner>
      <SomethingWentWrong errorMessage={errorMessage} />
    </>
  );
};

export default Playlist;
