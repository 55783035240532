import React from "react";

/**
 * Spinner should be able to take a HEIGHT property at some point.
 * Color and what not are set in _spinner.scss.
 */
const Spinner = ({ fetching, children, adjust }) => {
  if (fetching) {
    return (
      <div
        className="animated fadeIn text-center d-flex align-items-center justify-content-center"
        style={{ height: adjust ? "100%" : "80vh" }}
      >
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {children}
      </>
    );
  }
};

export default Spinner;
