import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import FavoriteTrackIcon from "../Tracks/FavoriteTrackIcon";
import SpeedControl from "../PlayerBar/SpeedControl";
import { PlayerContext } from "../../providers/PlayerProvider";

const MobilePlayerTopbar = ({ handleHide }) => {

  const {activeTrack } = useContext(PlayerContext);
  const { id } = activeTrack || {};

  return (
    <div className="row">
      <div className="col-12">
        <div className="px-3 py-3 d-flex justify-content-between">
          <button
            type="button"
            className="no-style has-svg text-white"
            onClick={() => handleHide()}
          >
            <FontAwesomeIcon icon={faArrowDown} size="lg" />
          </button>

          <div className="d-flex">
            <FavoriteTrackIcon
              id={id}
              makeWhite
              smallIcon
            />
            <SpeedControl smallIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePlayerTopbar;
