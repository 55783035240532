import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { analytics, firestore } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { toast } from "react-toastify";
import { useEffect } from "react";

const minNameLen = 3;
const maxNameLen = 30;

/**
 * Button that kicks off the process
 * @param {*} props
 */
const ButtonNewPlaylist = props => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Button className={props.className} onClick={() => setModalShow(true)}>
        {props.children}
      </Button>

      <ModalNewPlaylist show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

/**
 * Modal dialog with input field for title
 * @param {*} props
 */
const ModalNewPlaylist = props => {
  let inputRef = React.createRef();
  const user = React.useContext(UserContext);
  const [playlistTitle, setPlaylistTitle] = React.useState("");
  const [showMinMaxLength, setShowMinMaxLength] = React.useState(false);

  useEffect(() => {
    if (playlistTitle.length >= minNameLen && playlistTitle.length <= maxNameLen) {
      setShowMinMaxLength(false);
    }
  }, [playlistTitle]);

  const handleChange = e => {
    setPlaylistTitle(e.target.value);
  };

  const handleSubmit = async () => {
    if (playlistTitle.length < minNameLen || playlistTitle.length > maxNameLen) {
      setShowMinMaxLength(true);
      return;
    }

    const newPlaylist = {
      playlistTitle,
      dateCreated: new Date(),
      userId: user.uid,
      tracks: [],
      type: 'standard'
    };

    await firestore
      .collection('playlists')
      .add(newPlaylist);

    analytics.logEvent('playlist_created');
    hide();
    setPlaylistTitle("");
    toast("Your playlist has been created! Now find tracks you can add to it.");
  };

  const hide = () => {
    props.onHide();
    setPlaylistTitle("");
    setShowMinMaxLength(false);
  }

  const handleEntered = () => {
    inputRef.current.focus();
  };

  return (
    <Modal
      {...props}
      aria-labelledby="new-playlist-modal"
      onEntered={handleEntered}
      onExit={() => setPlaylistTitle("")}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="new-playlist-modal">Create a new playlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="input-group input-group-lg">
          <label className="sr-only" htmlFor="playlist-title">
            Playlist Title
          </label>
          <input
            ref={inputRef}
            type="text"
            className="form-control rounded"
            id="playlist-title"
            placeholder="Give your playlist a catchy name!"
            aria-label="Give your playlist a catchy name!"
            value={playlistTitle}
            onChange={handleChange}
          />
        </div>
        {
          showMinMaxLength &&
          <div
            className="mt-3"
            style={{ color: 'red' }}
          >
            Playlist name must be between {minNameLen} and {maxNameLen} characters long. The current length is {playlistTitle.length}.
          </div>
        }
      </Modal.Body>
      <Modal.Footer style={{ border: 0 }}>
        <button
          type="button"
          className="btn btn-soft-secondary btn-sm"
          onClick={hide}
        >
          Close
        </button>
        <Button
          className="btn btn-primary btn-sm"
          onClick={() => handleSubmit()}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ButtonNewPlaylist;
