import React, { useContext } from "react";
import { PlayerContext } from "../../providers/PlayerProvider";

const MobilePlayerImage = () => {

  const {activeTrack } = useContext(PlayerContext);
  const { title, cover } = activeTrack || {};
  
  return (
    <div className="px-6 mb-3">
      {cover ? (
        <img
          src={cover}
          alt={`Currently playing: ${title}`}
          style={{ maxWidth: "100%" }}
          className="rounded-lg"
        />
      ) : (
        <div className="bg-secondary make-square rounded-lg" />
      )}
    </div>
  );
};

export default MobilePlayerImage;
