import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { firestore, fieldValue } from '../firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

import { UserContext } from '../providers/UserProvider';

import './ReportTrack.scss';

const ReportTrack = ({ id }) => {
  const user = useContext(UserContext);
  const [modalShow, setModalShow] = React.useState(false);

  const reportTrack = async () => {
    firestore
      .collection('reportedTracks')
      .doc(id)
      .set({
        reporters: fieldValue.arrayUnion(user.uid),
        lastReportedDate: new Date()
      }, { merge: true });

    setModalShow(false);
  };

  return (
    <>
      <button
        onClick={() => setModalShow(true)}
        title="Report track for inappropriate content"
        type="button"
        className="no-style no-padding has-svg d-md-inline-block"
      >
        Report Track
      </button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        contentClassName="report-track-modal"
      >
        <div className="text-center my-4">
          <FontAwesomeIcon
            icon={faExclamation}
            size="6x"
            color="#EE2F2C"
          />
        </div>

        <div className="text-container">
          Report track for inappropriate content?
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="btn btn-pill btn-report"
            onClick={reportTrack}
          >
            Report
          </button>
          <button
            className="btn btn-pill btn-cancel"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </button>
        </div>

      </Modal>
    </>
  );
};

export default ReportTrack;