import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

const PromiseSpinner = () => {
	const { promiseInProgress } = usePromiseTracker({ delay: 500 })

	return (
		promiseInProgress &&
		<div
			className="animated w-100 fadeIn text-center d-flex align-items-center justify-content-center p-fixed"
			style={{
				height: "100%",
				zIndex: 9999,
				position: 'fixed',
				backgroundColor: 'rgba(0,0,0,0.3)',
			}}
		>
			<div className="lds-default">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default PromiseSpinner;