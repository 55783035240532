import React from "react";
import Moment from "react-moment";
import { firestore, functions, storage } from "./firebase";

/**
 * Collect IDs and Docs
 * @param {*} doc
 */
export const collectIdsAndDocs = doc => {
  return { id: doc.id, ...doc.data() };
};

/**
 * Return a moment formatted Date
 * @param {} date : Moment qualified
 * @param {*} format : Moment
 */
export function makeDate(date, format) {
  return <Moment format={format ? format : "L hh:mma"}>{date.toDate()}</Moment>;
}

/**
 * https://www.4codev.com/javascript/convert-seconds-to-time-value-hours-minutes-seconds-idpx6943853585885165320.html
 * 
 * Usage:
 *    const yourTime = convertHMS(4600); // 4600 seconds
      // yourTime is 01:16:40
 * 
 * @param {*} value 
 * @returns HH:MM:SS
 */
export function convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return { hours, minutes, seconds };
}

/**
 * To display how much time left in track, in mobile fullscreen (at least)
 * @param {*} param0
 */
export function timeLeft({ hours, minutes, seconds }) {
  if (hours > 0) {
    return `${+hours} hour${hours > 1 ? "s" : ""} ${+minutes} minute${minutes !== 1 ? "s" : ""
      }`;
  }
  if (minutes > 0) {
    return `${+minutes} minute${minutes > 1 ? "s" : ""} ${+seconds} second${seconds !== 1 ? "s" : ""
      }`;
  } else return `${+seconds} second${seconds !== 1 ? "s" : ""}`;
}

/**
 * Time displayed on progress bar
 * @param {*} param0
 */
export function progressBarTime({ hours, minutes, seconds }) {
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function uniqueItemsByKey(inputArr, key) {
  let resArr = [];
  inputArr.forEach((item) => {
    let i = resArr.findIndex(x => x[key] === item[key]);
    if (i <= -1) {
      resArr.push(item);
    }
  });

  return resArr;
}

export const getAudioListForSeries = async (id) => {

  let series = (await firestore
    .collection('series')
    .doc(id)
    .get())
    .data();

  const coverUrl = series.coverUrl ? await storage.ref(series.coverUrl).getDownloadURL() : window.ASSETS + "/app-ui/gradient-book-icon.png";

  const trackPromises = series.tracks.map(trackId => {
    return firestore
      .collection('tracks')
      .doc(trackId)
      .get();
  });

  let tracks = (await Promise.all(trackPromises)).map(collectIdsAndDocs);

  if (series.type === 'podcast') {
    tracks = tracks.reverse();
  }

  const downloadUrls = (await functions.httpsCallable('getTrackDownloadUrls')({trackIds: tracks.map(track => track.id)})).data.tracks;

  return tracks.map(track => {
    return {
      ...track,
      src: downloadUrls[track.id],
      cover: coverUrl,
      seriesTitle: series.title,
      seriesAuthor: series.author
    }
  })

};

export const getAudioListForPlaylists = async (id) => {

  let playlist = (await firestore
    .collection('playlists')
    .doc(id)
    .get())
    .data();

  const trackPromises = playlist.tracks.map(trackId => {
    return firestore
      .collection('tracks')
      .doc(trackId)
      .get();
  });

  let tracks = (await Promise.all(trackPromises)).map(collectIdsAndDocs);

  const downloadUrls = (await functions.httpsCallable('getTrackDownloadUrls')({trackIds: tracks.map(track => track.id)})).data.tracks;

  const seriesPromises = tracks.map(track => {
    return firestore
      .collection('series')
      .doc(track.seriesId)
      .get();
  });

  const series = (await Promise.all(seriesPromises)).map(collectIdsAndDocs);

  const coverPromises = series.map(serie => {
    if (serie.coverUrl) {
      return storage.ref(serie.coverUrl).getDownloadURL();
    } else {
      return Promise.resolve(window.ASSETS + "/app-ui/gradient-book-icon.png");
    }
  });

  const covers = await Promise.all(coverPromises);

  return tracks.map((track, index) => {
    return {
      ...track,
      src: downloadUrls[track.id],
      cover: covers[index],
      seriesTitle: series[index].title
    }
  });
};

export const removeSearchKey = () => {
  window.localStorage.removeItem('searchKey');
  window.localStorage.removeItem('searchKeyTime');
};

export const getSearchKey = async () => {
  let cachedSearchKey = window.localStorage.getItem('searchKey');
  let cachedSearchKeyTime = window.localStorage.getItem('searchKeyTime');
  let within7Days = false;

  /* Cache the search key for 7 days */
  if (cachedSearchKeyTime) {
    const oldDate = new Date(cachedSearchKeyTime);
    const today = new Date();

    const differenceInDays = (today.getTime() - oldDate.getTime()) / (1000 * 3600 * 24);

    if (differenceInDays < 7) {
      within7Days = true;
    }
  }

  if (cachedSearchKey && cachedSearchKeyTime && cachedSearchKeyTime && within7Days) {
    return cachedSearchKey;
  } else {
    const getSearchKey = functions.httpsCallable('getSearchKey');

    try {
      const result = await getSearchKey();

      if (result && result.data && result.data.length) {
        window.localStorage.setItem('searchKey', result.data);
        window.localStorage.setItem('searchKeyTime', new Date().toISOString());
        return result.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
};


export const getDaysTillTokenText = (user) => {
  const daysTillNextToken = getDaysTillToken(user);
  return `${daysTillNextToken} ${daysTillNextToken === 1 ? "Day" : "Days"}`;
};

export const getDaysTillToken = (user) => {
  if (!user.private || !user.private.lastMonthTokenAllocation) return;

  const now = new Date();
  const lastTokenAllocation = user.private.lastMonthTokenAllocation.toDate();

  return Math.round(30 - ((now.getTime() - lastTokenAllocation.getTime()) / (1000 * 3600 * 24)));

};

export const getMonthlyTokenAmount = (user) => {
  if (!user.private || !user.private.subscription) return;

  if (user.private.subscription === 'EXTRA') {
    return 4;
  } else if (user.private.subscription === 'SUPER') {
    return 6;
  }
};
