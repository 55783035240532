import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "react-router-dom";
import { signOut } from "../../firebase";
import ButtonBack from "../ButtonBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

import { STORIER_LOGO } from "../../constants/Metadata";
import Dropdown from "react-bootstrap/Dropdown";

import './Topbar.scss';

const NAVS = [
  {
    label: "Home",
    link: "/",
    icon: "fas fa-home",
    key: 1
  },
  {
    label: "Search",
    link: "/search",
    icon: "fas fa-search",
    key: 2
  },
  {
    label: "Saved",
    link: "/mylibrary",
    icon: "fas fa-book",
    key: 3
  }
];

const renderUser = user => {
  const { displayName } = user;

  if (displayName) return `Hello, ${user.displayName}!`

  return ""
}

const TopBar = () => {
  const contextUser = useContext(UserContext);
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(contextUser);
  }, [contextUser]);

  return (
    <header
      id="top-bar"
      className="bg-white text-white d-flex justify-content-between-- align-items-center shadow-- border-bottom"
    >
      <div id="logo">
        <Link to="/">
          <img src={STORIER_LOGO} alt="Storier Logo" />
        </Link>
      </div>
      <div id="back-button-loc" className="ml-3">
        <ButtonBack className="no-style has-svg text-secondary">
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </ButtonBack>
      </div>
      <div className="topbar_right">
        <Nav className="ml-auto">

          {NAVS.map(n => {
            return (
              <NavLink
                exact={true}
                to={n.link}
                key={n.label}
                className="nav-link"
                activeClassName="active"
              >
                {n.label}
              </NavLink>
            );
          })}
          <Dropdown alignRight className="no-caret-fix-margin">
            <Dropdown.Toggle
              variant="white"
              className="text-body"
              id="AccountDropdown"
            >
              <i className="far fa-user fa-lg" />
              <span className="d-none d-md-inline-block ml-1">
                {user ? renderUser(user) : null}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={`${window.SITE}/account`}>
                Account
              </Dropdown.Item>
              <Dropdown.Item onClick={signOut}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </div>
    </header>
  );
};

export default TopBar;
