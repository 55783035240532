import { useContext } from "react";
import { FavoriteTracksContext } from "../providers/FavoriteTracksProvider";
import { analytics, firestore } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import { toast } from "react-toastify";
import firebase from "../firebase";

const useFavoriteTracks = () => {
  const user = useContext(UserContext);
  const favoriteTracksPlaylist = useContext(FavoriteTracksContext);

  const favoritesInclude = (trackId) => {
    return  favoriteTracksPlaylist &&
      favoriteTracksPlaylist.tracks &&
      favoriteTracksPlaylist.tracks.includes(trackId);
  };

  const toggleFavorite = async (trackId) => {

    /* Playlist does not exist, let's create it */
    if (!user.favoriteTracksId) {
      const result = await firestore
        .collection('playlists')
        .add({
          tracks: [trackId],
          type: 'favoriteTracks',
          dateCreated: new Date(),
          userId: user.uid
        });

      analytics.logEvent('favorites_track_added', {
        trackId
      });

      await firestore
        .collection('users')
        .doc(user.uid)
        .update({
          favoriteTracksId: result.id
        });

      return;
    }

    const favoriteTrackRef = firestore
      .collection('playlists')
      .doc(user.favoriteTracksId);

    // Remove Favorite Track
    if (favoritesInclude(trackId)) {

      favoriteTrackRef
        .update({
          tracks: firebase.firestore.FieldValue.arrayRemove(trackId)
        })
        .then(() => {
          analytics.logEvent('favorites_track_removed', {
            trackId
          });
          toast.dismiss();
          toast("Removed track from favorites.");
        })
        .catch(error => {
          toast.dismiss();
          toast.error("Whoops, something went wrong! Try again later!");
          console.error("Error toggling favorite: ", error);
        });
    } else {
      // Add Favorite Track
      favoriteTrackRef
        .update({tracks: firebase.firestore.FieldValue.arrayUnion(trackId)})
        .then(() => {
          analytics.logEvent('favorites_track_added', {
            trackId
          });
          toast.dismiss();
          toast("Added track to favorites!");
        })
        .catch(error => {
          toast.dismiss();
          toast.error("Whoops, something went wrong! Try again later.");
          console.error("Error toggling favorite: ", error);
        });
    }

  };

  return {
    favoritesInclude,
    favoriteTracksPlaylist,
    toggleFavorite
  };
};

export default useFavoriteTracks;
