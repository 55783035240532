import React from "react";
import { useHistory } from "react-router-dom";
import { analytics, firestore } from "../../firebase";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const reactSwal = withReactContent(Swal);

const ButtonDeletePlaylist = props => {
  const { playlistId } = props;
  const history = useHistory();

  function handleDelete(playlistId) {
    reactSwal
      .fire({
        title: "Do you really want to delete this playlist?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes!"
      })
      .then(result => {
        if (result.value) {
          firestore
            .collection("playlists")
            .doc(playlistId)
            .delete()
            .then(() => {
              analytics.logEvent('playlist_deleted');
              toast("The playlist has been deleted.");
              history.push("/mylibrary");
            })
            .catch(error => {
              toast.error("Whoops, something went wrong! Try again later!");
              console.error("Error removing document: ", error);
            });
        }
      });
  }

  return (
    <button
      type="button"
      className="btn btn-pill btn-soft-secondary px-4"
      onClick={() => handleDelete(playlistId)}
    >
      Delete Playlist
    </button>
  );
};

export default ButtonDeletePlaylist;
