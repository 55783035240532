import React from "react";

const PlayerControlButton = props => {
  const { label, handleClick, disabled, className } = props;
  return (
    <button
      type="button"
      title={label}
      className={`no-style d-flex align-items-center svgButton ${
        disabled ? "text-muted" : "text-white"
      } ${className}`}
      onClick={handleClick}
      disabled={disabled}
      style={{ height: "100%" }}
    >
      {props.children}
    </button>
  );
};

export { PlayerControlButton };
