import React from "react";
import SpeedControl from "./SpeedControl";
import VolumeSlider from "./VolumeSlider";

const SecondaryControls = () => {
  return (
    <div className="row no-gutters d-flex align-items-center">
      <div className="col d-flex">
        <div className="ml-auto pt-2 mr-2 row">
          <SpeedControl />
        </div>
      </div>
      <div className="col-7">
        <div className="pr-4">
          <VolumeSlider />
        </div>
      </div>
    </div>
  );
};

export default SecondaryControls;
