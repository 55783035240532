
export const AGE_GROUPS = [
  {
    label: 'Baby',
    subLabel: 'Ages 0-1',
    id: 'ages-0-to-1',
    icon: window.ASSETS + '/age-groups/feeding-bottle.svg',
    bgColor: '#09a5be',
    collection: 'age-group',
  },
  {
    label: 'Toddler',
    subLabel: 'Ages 2-3',
    id: 'ages-2-to-3',
    icon: window.ASSETS + '/age-groups/teddy-bear.svg',
    bgColor: '#de4437',
    collection: 'age-group',
  },
  {
    label: 'Preschool',
    subLabel: 'Ages 4-6',
    id: 'ages-4-to-6',
    icon: window.ASSETS + '/age-groups/pencils.svg',
    bgColor: '#6f42c1',
    collection: 'age-group',
  },
  {
    label: 'Gradeschool',
    subLabel: 'Ages 7-12',
    id: 'ages-7-to-12',
    icon: window.ASSETS + '/age-groups/apple.svg',
    bgColor: '#28a745',
    collection: 'age-group',
  },
];

export const GENRES = [
  {
    label: 'Classics',
    id: 'classics',
    icon: window.ASSETS + '/genres/pen.svg',
    collection: 'genre',
  },
  {
    label: 'Literature & Fiction',
    id: 'literature-and-fiction',
    icon: window.ASSETS + '/genres/owl.svg',
    collection: 'genre',
  },
  // {
  //   label: "Comics & Graphic Novels",
  //   id: "comics-and-graphic-novels"
  // },
  {
    label: 'Education & Reference',
    id: 'education-and-reference',
    icon: window.ASSETS + '/genres/pencil.svg',
    collection: 'genre',
  },
  {
    label: 'Action & Adventure',
    id: 'action-and-adventure',
    icon: window.ASSETS + '/genres/adventure.svg',
    collection: 'genre',
  },
  {
    label: 'History',
    id: 'history',
    icon: window.ASSETS + '/genres/movie.svg',
    collection: 'genre',
  },
  {
    label: 'Early Learning',
    id: 'early-learning',
    icon: window.ASSETS + '/genres/blocks.svg',
    collection: 'genre',
  },
  {
    label: 'Growing Up & Facts of Life',
    id: 'growing-up-and-facts-of-life',
    icon: window.ASSETS + '/genres/healthcare.svg',
    collection: 'genre',
  },
  {
    label: 'Humor',
    id: 'humor',
    icon: window.ASSETS + '/genres/funny.svg',
    collection: 'genre',
  },
  {
    label: 'Science, Nature & How it Works',
    id: 'science-nature-and-how-it-works',
    icon: window.ASSETS + '/genres/robotics.svg',
    collection: 'genre',
  },
  {
    label: 'Animals',
    id: 'animals',
    icon: window.ASSETS + '/genres/pet.svg',
    collection: 'genre',
  },
  {
    label: 'Fairy Tales, Folk Tales & Myths',
    id: 'fairy-tales-folk-tales-and-myths',
    icon: window.ASSETS + '/genres/gnome.svg',
    collection: 'genre',
  },
  {
    label: 'Activities, Crafts & Games',
    id: 'activities-crafts-and-game',
    icon: window.ASSETS + '/genres/rubik.svg',
    collection: 'genre',
  },
];

export const TYPES = [
  {
    label: 'Audiobooks',
    id: 'audiobook',
    icon: window.ASSETS + '/onlinelearning/color/022-audiobooks.svg',
    lineIcon: window.ASSETS + '/onlinelearning/outline/022-audiobooks.svg',
    iconClass: 'fa-book-open fas',
    collection: 'type',
    bgColor: '#3F729B',
  },
  {
    label: 'Podcasts',
    id: 'podcast',
    icon: window.ASSETS + '/audiovideo/color/026-microphone.svg',
    lineIcon: window.ASSETS + '/audiovideo/outline/026-microphone.svg',
    iconClass: 'fa-microphone-alt fas',
    collection: 'type',
    bgColor: '#3F729B',
  },
  {
    label: 'Music',
    id: 'music',
    icon: window.ASSETS + '/finearts/color/046-music.svg',
    lineIcon: window.ASSETS + '/finearts/outline/046-music.svg',
    iconClass: 'fa-music fas',
    collection: 'type',
    bgColor: '#3F729B',
  },
];

export const STORIER_LOGO = window.ASSETS + '/storier/logo.png';
export const PLAYER_PLACEHOLDER = window.ASSETS + '/app-ui/search.svg';
export const LOST_EXPLORER = window.ASSETS + '/app-ui/lost-explorer.png';
export const SOMETHING_WRONG = window.ASSETS + '/app-ui/something-went-wrong.png';
export const STAR_CIRCLE = window.ASSETS + '/app-ui/star-circle.svg';
export const HEADPHONES = window.ASSETS + '/app-ui/headphones.svg';
export const SPEEDOMETER = window.ASSETS + '/app-ui/speedometer.svg';
export const BOOKMARK_RED = window.ASSETS + '/app-ui/bookmark-red.svg';
export const SEARCH = window.ASSETS + '/app-ui/search.svg';
export const BOOKS = window.ASSETS + '/app-ui/books.svg';
