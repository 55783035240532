import React, { useContext, useEffect } from "react";
import useMobilePlayer from "../../hooks/useMobilePlayer";
import disableScroll from "disable-scroll";
import "../../style/FullScreenPlayer.scss";
import { PlayerContext } from "../../providers/PlayerProvider";
import MobilePlayerTopbar from "./MobilePlayerTopbar";
import MobilePlayerImage from "./MobilePlayerImage";
import MobilePlayerProgress from "./MobilePlayerProgress";
import MobilePlayerControls from "./MobilePlayerControls";

const FullScreenPlayer = () => {
  const { fullPlayerVisibility, toggleVisibility } = useMobilePlayer();
  const { activeTrack } = useContext(PlayerContext);
  const { title, seriesTitle } = activeTrack || {};

  function handleHide() {
    disableScroll.off();
    toggleVisibility();
  }

  useEffect(() => {
    if (fullPlayerVisibility) disableScroll.on();
  }, [fullPlayerVisibility]);

  return (
    <div
      id="fullScreenPlayer"
      className={`bg-navy text-white ${!fullPlayerVisibility && "d-none"}`}
    >
      <div
        className="d-flex flex-column justify-content-between"
        style={{ minHeight: "100vh" }}
      >
        <MobilePlayerTopbar handleHide={handleHide} />
        <MobilePlayerImage />
        <div className="text-center py-3 px-3">
          <div className="text-white single-line">{title}</div>
          <div className="small text-muted">{seriesTitle}</div>
        </div>
        <MobilePlayerProgress />
        <MobilePlayerControls />
      </div>
    </div>
  );
};

export default FullScreenPlayer;
