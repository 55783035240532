import React from "react";
import { SOMETHING_WRONG } from "../../constants/Metadata";

const SomethingWentWrong = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <section
      className="animated fadeIn text-center d-flex align-items-center justify-content-center"
      style={{ height: "80vh" }}
    >
      <div className="pb-8" style={{ maxWidth: 480 }}>
        <img src={SOMETHING_WRONG} alt="" className="w-100" />
        <div className="text-center">
          <h2>Something went wrong!</h2>
          <p className="lead">{errorMessage}</p>
        </div>
      </div>
    </section>
  );
};

export default SomethingWentWrong;
