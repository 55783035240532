import React, { useState, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";
import { collectIdsAndDocs } from "../utilities";

const FavoriteTracksContext = React.createContext({
  favoriteTracksPlaylist: null
});

const FavoriteTracksProvider = props => {
  const user = useContext(UserContext);

  const [favoriteTracksPlaylist, setFavoriteTracksPlaylist] = useState([]);

  useEffect(() => {
    if (user && user.favoriteTracksId) {
      const unsubscribe = firestore
        .collection('playlists')
        .doc(user.favoriteTracksId)
        .onSnapshot(snapshot => {
          setFavoriteTracksPlaylist(collectIdsAndDocs(snapshot));
        });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <FavoriteTracksContext.Provider value={favoriteTracksPlaylist}>
      {props.children}
    </FavoriteTracksContext.Provider>
  );
};

export { FavoriteTracksContext, FavoriteTracksProvider };
