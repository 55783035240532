import React, { useEffect, useState } from "react";
import { AGE_GROUPS, GENRES } from "../constants/Metadata";
import { Link } from "react-router-dom";

const LabelButtons = ({ type, ageGroup, genre }) => {
  const [{ genreLabel, ageGroupLabel }, setLabels] = useState({
    genreLabel: "",
    ageGroupLabel: ""
  });

  useEffect(() => {
    // Get labels for ageGroup and genre
    const labelAgeGroup = AGE_GROUPS.filter(a => a.id === ageGroup)[0];
    const labelGenre = GENRES.filter(a => a.id === genre)[0];
    if (labelAgeGroup && labelGenre) {
      setLabels({
        ageGroupLabel: labelAgeGroup.label,
        genreLabel: labelGenre.label
      });
    }
  }, [ageGroup, genre]);

  return (
    <div className="small">
      <button
        type="button"
        className="btn btn-xs btn-soft-secondary mr-1"
        style={{ cursor: "default" }}
      >
        {type}
      </button>
      {ageGroupLabel && (
        <Link
          to={`/browse/age-group/${ageGroup}`}
          className="btn btn-xs btn-soft-secondary mr-1"
        >
          {ageGroupLabel}
        </Link>
      )}
      {genreLabel && (
        <Link
          to={`/browse/genre/${genre}`}
          className="btn btn-xs btn-soft-secondary"
        >
          {genreLabel}
        </Link>
      )}
    </div>
  );
};

export default LabelButtons;
