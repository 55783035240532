import React from "react";
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? "#F7FAFF" : "white",
  zIndex: 100,

  // styles we need to apply on draggables
  ...draggableStyle
});

const DraggableItem = ({ index, children }) => {
  return (
    <Draggable
      key={index}
      draggableId={`${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {React.cloneElement(children, { draggableProps: provided.dragHandleProps })}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;
