import React, { useEffect, useState, useRef, useContext } from "react";
import { getAudioListForSeries, getAudioListForPlaylists } from "../utilities";
import useListenHistory from "../hooks/useListenHistory";
import { analyticsFactory } from "../firebase";
import { UserContext } from "./UserProvider";

const PlayerContext = React.createContext({});

export const PLAY_MODES = {
  ORDER: 'order',
  LOOP: 'orderLoop',
  SINGLE_LOOP: 'singleLoop',
  SHUFFLE: 'shufflePlay'
};

/* used because setTimeout is stupid. */
let activeTrackGlobal = null;

const PlayerProvider = props => {
  const audioRef = useRef();
  const { addToHistory, addToTrackHistory } = useListenHistory();
  const user = useContext(UserContext);

  const [audioList, setAudioList] = useState([]);
  const [playIndex, setPlayIndex] = useState(0);
  const [activeListId, setActiveListId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playMode, setPlayMode] = useState(PLAY_MODES.ORDER);
  const [volume, setVolume] = useState(0.25);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [tracker, setTracker] = useState(null);
  const activeTrack = audioList[playIndex];

  /* Analytics */
  const onTrackStarted = (refTrack) => {

    setTimeout(() => {
      if (refTrack.id === activeTrackGlobal.id) {
        //TODO: replace with storier analytics lib
        addToTrackHistory(refTrack.id);
      }
    }, 3000);
  };

  const onTrackEnded = (endTime) => {
    if (isPlaying) {
      tracker.end(endTime);
    }
  };

  useEffect(() => {
    if (audioList.length > playIndex) {
      const aNewTracker = analyticsFactory.createTracker({ id: user.uid, isSubscribed: user.private.subscriptionActive }, activeTrack.seriesId, activeTrack.id);
      setTracker(aNewTracker);
      activeTrackGlobal = activeTrack;
      onTrackStarted(activeTrack, aNewTracker);
      configureMediaSession(activeTrack);
    }
    // eslint-disable-next-line
  }, [activeTrack]);

  const configureMediaSession = (track) => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: track.title,
        artist: track.seriesAuthor,
        album: track.seriesTitle,
        artwork: [{
          src: track.cover,
          sizes: "96x96 128x128 192x192 256x256 384x384 512x512",
        }]
      });
      navigator.mediaSession.setActionHandler('play', play);
      navigator.mediaSession.setActionHandler('pause', pause);
      navigator.mediaSession.setActionHandler('seekbackward', jogBackward);
      navigator.mediaSession.setActionHandler('seekforward', jogForward);
      navigator.mediaSession.setActionHandler('previoustrack', prev);
      navigator.mediaSession.setActionHandler('nexttrack', next);
    }
  };

  const playTracks = async (id, collection, trackId) => {
    if (isPlaying) {
      onTrackEnded(currentTime);
    }

    // Already playing this list, just fast forward to correct item
    if (activeListId === id) {
      let index = 0;
      if (trackId) {
        index = audioList.findIndex(item => item.id === trackId);
      }
      setPlayIndex(index);
      return;
    }

    // stop();
    let newAudioList = [];

    if (collection === 'series') {
      newAudioList = await getAudioListForSeries(id);
    } else if (collection === 'playlists') {
      newAudioList = await getAudioListForPlaylists(id);
    }

    setActiveListId(id);
    setAudioList(newAudioList);
    addToHistory(id, collection);

    let index = 0;
    if (trackId) {
      index = newAudioList.findIndex(item => item.id === trackId);
    }

    setPlayIndex(index);
  };

  const onCanPlay = () => {
    play();
  };

  const play = () => {
    tracker.start(currentTime);

    audioRef.current.playbackRate = playbackRate;
    audioRef.current.play();
  };

  const onPlaying = () => {
    setIsPlaying(true);
  };

  const pause = () => {
    tracker.end(currentTime);

    audioRef.current.pause();
  };

  const onPause = () => {
    setIsPlaying(false);
  };

  const togglePlay = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const jogBackward = () => {
    audioRef.current.currentTime -= 30;

    play();
  };

  const jogForward = () => {
    audioRef.current.currentTime += 30;

    play();
  };

  const next = (dontLoop) => {
    const realDontLoop = dontLoop instanceof Boolean && dontLoop;
    onTrackEnded(currentTime);

    if (playIndex >= audioList.length - 1 && !realDontLoop) {
      /* Loop back to the beginning */
      setPlayIndex(0);
      audioRef.current.load();
    } else {
      setPlayIndex(playIndex + 1);
    }
  };

  const prev = () => {
    onTrackEnded(currentTime);

    if (playIndex === 0) {
      /* Loop back to the beginning */
      audioRef.current.load();
      onTrackStarted(audioList[playIndex], tracker);
    } else {
      setPlayIndex(playIndex - 1);
    }
  };

  const changePlaybackRate = (newRate) => {
    audioRef.current.playbackRate = newRate;
    setPlaybackRate(newRate);
  };

  const onRateChange = (e) => {
    if (e.target.playbackRate !== playbackRate) {
      audioRef.current.playbackRate = playbackRate;
    }
  };

  const changeVolume = (newVolume) => {
    newVolume = newVolume < 0 ? 0 : newVolume;
    newVolume = newVolume > 1 ? 1 : newVolume;

    audioRef.current.volume = newVolume;
  };

  const onVolumeChange = (e) => {
    setVolume(e.target.volume);
  };

  const changeCurrentTime = (newTime) => {
    if (isPlaying) {
      tracker.end(audioRef.current.currentTime);
    }

    tracker.start(newTime);

    audioRef.current.currentTime = newTime;
  };

  const onTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime);
  };

  const onDurationChange = (e) => {
    setDuration(e.target.duration);
  };

  const toggleShuffle = () => {
    const newPlayMode = playMode === PLAY_MODES.SHUFFLE ? PLAY_MODES.ORDER : PLAY_MODES.SHUFFLE;
    setPlayMode(newPlayMode);
  };

  const toggleLoop = () => {
    const newPlayMode = playMode === PLAY_MODES.LOOP ? PLAY_MODES.ORDER : PLAY_MODES.LOOP;
    setPlayMode(newPlayMode);
  };

  const onEnded = () => {
    next(true);
  };

  return (
    <PlayerContext.Provider value={{
      activeTrack,
      playTracks,
      togglePlay,
      activeListId,
      isPlaying: isPlaying,
      changeCurrentTime,
      currentTime: currentTime,
      duration,
      playMode,
      toggleShuffle,
      toggleLoop,
      next,
      prev,
      jogBackward,
      jogForward,
      volume: volume,
      changeVolume,
      playbackRate,
      changePlaybackRate
    }}>
      <audio
        autoPlay={false}
        controls={false}
        loop={playMode === PLAY_MODES.LOOP}
        muted={false}
        src={audioList && audioList[playIndex] ? audioList[playIndex].src : ''}
        ref={audioRef}

        onCanPlay={onCanPlay}
        onPlaying={onPlaying}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
        onDurationChange={onDurationChange}
        onEnded={onEnded}
        onRateChange={onRateChange}
        onVolumeChange={onVolumeChange}

      >
        <track kind="captions" srcLang="en" />
        Your browser does not support audio playing.
      </audio>
      {props.children}
    </PlayerContext.Provider>
  );
};

export { PlayerContext, PlayerProvider };
