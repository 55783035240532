import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as fasFaStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farFaStar } from "@fortawesome/free-regular-svg-icons";
import useFavoriteTracks from "../../hooks/useFavoriteTracks";
import { FavoriteTracksContext } from "../../providers/FavoriteTracksProvider";

const FavoriteTrackIcon = props => {
  const { id, makeWhite, smallIcon } = props;

  const [favorite, setFavorite] = useState(false);
  const { favoritesInclude, toggleFavorite } = useFavoriteTracks();
  const favoriteTracks = useContext(FavoriteTracksContext);

  useEffect(() => {
    setFavorite(favoritesInclude(id));
  }, [id, favoriteTracks, favoritesInclude]);

  return (
    <button
      type="button"
      className={`no-style ${makeWhite ? "text-white" : "text-warning"
        } svgButton`}
      onClick={() => toggleFavorite(id)}
    >
      <FontAwesomeIcon
        icon={favorite ? fasFaStar : farFaStar}
        size={smallIcon ? "1x" : "lg"}
        color="#EE2F2C"
      />
    </button>
  );
};

export default FavoriteTrackIcon;
