import React from "react";
import ButtonPlayNow from "../ButtonPlayNow";
import { makeDate } from "../../utilities";
import ButtonDeletePlaylist from "./ButtonDeletePlaylist";
import { HEADPHONES } from "../../constants/Metadata";
import TRACK_ICON from "../../assets/icons/TracksIcon.png";

const PlaylistHeader = ({
  id,
  playlistTitle,
  dateCreated,
  type,
  tracks
}) => {

  const isFavoritesPlaylist = type === 'favoriteTracks' || type === undefined;

  const renderDate = () => {
    if (!isFavoritesPlaylist && dateCreated) {
      return (
        <p className="lead text-muted mt-3">
          Created on {makeDate(dateCreated, "MMMM Do, YYYY")}
        </p>
      )
    }
  };

  const renderDelete = () => {
    if (!isFavoritesPlaylist) {
      return (
        <div className="d-inline-block ml-3">
          <ButtonDeletePlaylist playlistId={id} />
        </div>
      );
    }
  };

  return (
    <>
      <header className="space-top-md-1 space-bottom-2">
        <div className="text-center">
          <div className="mb-5">
            {
              isFavoritesPlaylist ?
                <div
                  style={{
                    backgroundColor: '#EE2F2C',
                    borderRadius: '1rem',
                    padding: '1.5rem',
                    width: '8rem',
                    height: '8rem',
                    margin: '0 auto'
                  }}
                >
                  <img
                    src={TRACK_ICON}
                    alt={playlistTitle}
                    style={{ height: `5rem` }}
                  />
                </div>
                :
                <img
                  src={HEADPHONES}
                  alt={playlistTitle}
                  style={{ height: `4rem` }}
                />
            }
          </div>

          <h1 className="display-4 heavy-font animated fadeIn">{isFavoritesPlaylist ? 'Favorite Tracks' : playlistTitle}</h1>

          {renderDate()}

          <div className="mt-4">
            {
              tracks.length !== 0 &&
              <div className="d-inline-block">
                <ButtonPlayNow
                  id={id}
                  collection="playlists"
                />
              </div>
            }
            {renderDelete()}
          </div>
        </div>
      </header>
    </>
  );
};

export default PlaylistHeader;
