import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { collectIdsAndDocs } from '../../utilities';
import SeriesCard from "../Series/SeriesCard";

const CollectionCard = ({ id }) => {
    const [series, setSeries] = useState({});

    useEffect(() => {
        firestore
            .collection("series")
            .doc(id)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setSeries(collectIdsAndDocs(doc));
                }
            });
    }, [id]);

    return (
        <SeriesCard key={id} {...series} />
    );
};

export default CollectionCard;