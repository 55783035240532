import React from "react";
import Card from "./Card";

const CardStack = props => {
  const { set, browseBy, large, formatAgeGroup } = props;

  return (
    <div className="row">
      {set.map(item => (
        <Card
          key={item.id}
          {...item}
          browseBy={browseBy}
          large={large}
          {...{ formatAgeGroup }}
        />
      ))}
    </div>
  );
};

export default CardStack;
