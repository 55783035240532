import React, { useContext } from "react";
import { PlaylistsContext } from "../../providers/PlaylistsProvider";
import IconCard from "../IconCard";
import AddNewPlaylist from "../Playlists/AddNewPlaylist";
import { HEADPHONES } from "../../constants/Metadata";

import StorierToken from '../../assets/icons/StorierToken.png'
import SeriesIcon from '../../assets/icons/SeriesIcon.png';
import TracksIcon from '../../assets/icons/TracksIcon.png';

import { UserContext } from "../../providers/UserProvider";

import './MyLibrary.scss';

const MyLibrary = () => {
  const playlists = useContext(PlaylistsContext);
  const user = useContext(UserContext);

  return (
    <div className="container-fluid animated fadeIn">
      <div className="row cardWrapper px-5">
        {
          user && user.private && (user.private.subscription === 'EXTRA' || user.private.subscription === 'SUPER') &&
          <IconCard
            label="Story Collection"
            linkTo="/story-collection"
            svg={StorierToken}
            bgColor="#FFBD00"
            className="myLibCard"
          />
        }

        <IconCard
          label="Favorite Series"
          linkTo="/favorite-series"
          svg={SeriesIcon}
          bgColor="#42B0D2"
          className="myLibCard"
        />

        <IconCard
          label="Favorite Tracks"
          linkTo="/favorite-tracks"
          svg={TracksIcon}
          bgColor="#EE2F2C"
          className="myLibCard"
        />

        <div className="w-100 mb-5 ml-3 mt-5">
          <h2 className="text-purple">My Playlists</h2>

          <div className="space-bottom-1 new-playlist-div">
            <AddNewPlaylist className="btn btn-pill px-10 bg-purple-light">
              Create a Playlist
            </AddNewPlaylist>
          </div>

          {playlists.map(({ playlistTitle, id }) => (
            <IconCard
              key={id}
              label={playlistTitle}
              linkTo={`/playlist/${id}`}
              svg={HEADPHONES}
              className="myLibCard"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyLibrary;
