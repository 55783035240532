import React from 'react';

import BlankToken from '../../assets/icons/BlankToken.png';

const TokenIcon = ({ className, value, width }) => {

	const reduceFactor = value < 10 ? .35 : .5;
	const fontSize = width - (width * reduceFactor);

	return (
		<div
			className={className}
			style={{
				width: width,
				height: width
			}}
		>
			<div
				style={{
					width: '100%',
					height: '100%',
					textAlign: 'center',
					position: 'relative'
				}}
			>
				<div
					style={{
						fontFamily: 'Sniglet',
						color: '#ffe990',
						fontWeight: 'bold',
						fontSize: fontSize,
						zIndex: 1,
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					{value}
				</div>
				<img
					src={BlankToken}
					alt="token"
					style={{
						width: width,
						height: width
					}}
				/>
			</div>
		</div>
	)
}

export default TokenIcon;