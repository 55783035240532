import React, { useState, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";
import { collectIdsAndDocs } from "../utilities";

const FavoriteSeriesContext = React.createContext();

const FavoriteSeriesProvider = props => {
  const user = useContext(UserContext);

  const [favoriteSeries, setFavoriteSeries] = useState([]);

  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection(`users/${user.uid}/favoriteSeries`)
        // .orderBy("dateAdded", "desc")
        .onSnapshot(snapshot => {
          const favoriteSeries = snapshot.docs.map(collectIdsAndDocs);
          setFavoriteSeries(favoriteSeries);
        });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <FavoriteSeriesContext.Provider value={favoriteSeries}>
      {props.children}
    </FavoriteSeriesContext.Provider>
  );
};

export { FavoriteSeriesContext, FavoriteSeriesProvider };
