import React from "react";
import ButtonPlayNow from "../ButtonPlayNow";
import ButtonFavoriteSeries from "../Favorites/ButtonFavoriteSeries";
import CoverArt from "../CoverArt";
import LabelButtons from "../LabelButtons";

import UseTokens from '../Tokens/UseTokens';

const SeriesHeader = ({
  title,
  author,
  coverUrl,
  id,
  description,
  type,
  isPremium,
  tokenPrice,
  isPurchased
}) => {
  return (
    <header className="space-top-md-1">
      <div className="d-md-flex align-items-end justify-content-start space-bottom-2">
        <div className="mr-md-6 text-center text-md-left">
          <div className="css-series-image">
            <CoverArt url={coverUrl} alt={title} />
          </div>
        </div>

        <div className="text-center text-md-left">
          <h1 className="heavy-font">{title}</h1>
          {author && <p className="lead text-muted">By {author}</p>}

          <div className="mt-3" style={{ paddingTop: 5 }}>
            <div className="mr-3 d-inline-block">
              {
                (!isPremium || isPurchased) ?
                <>
                    <ButtonPlayNow
                      id={id}
                      collection="series"
                    />
                    <ButtonFavoriteSeries seriesId={id} />
                  </>
                :
                <UseTokens price={tokenPrice} seriesId={id} />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="space-bottom-2">
        {description && (
          <div
            className="mb-5"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <LabelButtons {...{ type }} />
      </div>
    </header>
  );
};

export default SeriesHeader;
