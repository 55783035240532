import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStepBackward,
  faStepForward,
  faPlayCircle,
  faPauseCircle,
  faRandom,
  faUndoAlt,
  faRedoAlt,
  // faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PlayerControlButton } from "./PlayerControlButton";
import { PlayerContext, PLAY_MODES } from "../../providers/PlayerProvider";

const Controls = () => {

  const {
    activeTrack,
    isPlaying,
    togglePlay,
    toggleShuffle,
    playMode,
    // toggleLoop,
    next,
    prev,
    jogBackward,
    jogForward,
  } = useContext(PlayerContext);

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(!activeTrack);
  }, [activeTrack]);

  return (
    <div className="text-center">
      <div className="d-flex align-items-center justify-content-center">
        <PlayerControlButton
          label="Shuffle"
          handleClick={toggleShuffle}
          disabled={disable}
          className={playMode === PLAY_MODES.SHUFFLE ? 'text-success' : ''}
        >
          <FontAwesomeIcon icon={faRandom} size="lg" />
        </PlayerControlButton>

        <PlayerControlButton
          label="Back 30 seconds"
          handleClick={jogBackward}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faUndoAlt} size="lg" rotate="270" />
        </PlayerControlButton>

        <PlayerControlButton
          label="Step Backward"
          handleClick={prev}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faStepBackward} size="lg" />
        </PlayerControlButton>

        <PlayerControlButton
          label="Play/Pause"
          handleClick={togglePlay}
          disabled={disable}
        >
          <FontAwesomeIcon icon={isPlaying ? faPauseCircle : faPlayCircle} size="2x" />
        </PlayerControlButton>

        <PlayerControlButton
          label="Step Forward"
          handleClick={next}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faStepForward} size="lg" />
        </PlayerControlButton>

        <PlayerControlButton
          label="Forward 30 seconds"
          handleClick={jogForward}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faRedoAlt} size="lg" rotate="90" />
        </PlayerControlButton>

        {/*<PlayerControlButton*/}
          {/*label="Repeat"*/}
          {/*handleClick={toggleLoop}*/}
          {/*disabled={disable}*/}
          {/*className={playMode === PLAY_MODES.LOOP ? 'text-success' : ''}*/}
        {/*>*/}
          {/*<FontAwesomeIcon icon={faExchangeAlt} size="lg" />*/}
        {/*</PlayerControlButton>*/}
      </div>
    </div>
  );
};

export default Controls;
