import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faPause,
  faPlay
} from "@fortawesome/free-solid-svg-icons";
import useMobilePlayer from "../../hooks/useMobilePlayer";
import { PlayerControlButton } from "./PlayerControlButton";
import { PlayerContext } from "../../providers/PlayerProvider";
import { PLAYER_PLACEHOLDER } from "../../constants/Metadata";

const AlbumInfo = () => {
  const { toggleVisibility } = useMobilePlayer();
  const { activeTrack, togglePlay, isPlaying } = useContext(PlayerContext);
  const { title, cover, seriesTitle } = activeTrack || {};

  return (
    <div id="album-info" className="animated fadeIn">
      <div className="media d-flex align-items-center w-100 px-2">
        <div className="player-thumbnail">
          <div style={{ position: "relative" }}>
            {cover ? (
              <>
                <img
                  src={cover}
                  className="rounded-sm animated fadeIn player-thumbnail"
                  alt={`Cover of ${seriesTitle}`}
                  style={{ position: "absolute" }}
                />
                <div id="play-pause-square" className="d-md-none">
                  <PlayerControlButton
                    className="w-100"
                    label="Play/Pause"
                    handleClick={togglePlay}
                  >
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} size="lg"/>
                  </PlayerControlButton>
                </div>
              </>
            ) : (
              <Link to="/search">
                <img
                  src={PLAYER_PLACEHOLDER}
                  alt="Storier!"
                  className="player-thumbnail"
                />
              </Link>
            )}
          </div>
        </div>
        <div className="ml-md-2 ml-3 has-svg d-flex pr-3">
          {title ? (
            <div className="animated fadeIn">
              <div className="css-truncate small text-white d-block font-weight-bold">
                {title}
              </div>
              <div className="css-truncate small text-muted">{seriesTitle}</div>
            </div>
          ) : (
            <div className="animated fadeIn">
              <Link to="/search" className="text-white">
                <em>
                  <span role="img" aria-label="Sparkle emoji">
                    ✨
                  </span>{" "}
                  Find something that sparks your interest...{" "}
                  <span role="img" aria-label="Sparkle emoji">
                    ✨
                  </span>
                </em>
              </Link>
            </div>
          )}
          {activeTrack && (
            <button className="no-style p-2 d-md-none" onClick={toggleVisibility}>
              <FontAwesomeIcon icon={faExpand} size="lg" className="text-white"/>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlbumInfo;
