import React from "react";
import { STORIER_LOGO } from "../../constants/Metadata";
import {
  signInWithEmail,
} from "../../firebase";
import useInput from "../../hooks/useInput";

/***************
 * Sign in page
 **************/
const SignIn = () => {
  const [email, emailInput] = useInput({
    type: "email",
    name: "email",
    placeholder: "Enter your email address!"
  });

  const [password, passwordInput] = useInput({
    type: "password",
    name: "password",
    placeholder: "Enter your password!"
  });

  const handleSubmit = async e => {
    e.preventDefault();
    signInWithEmail(email, password);
  };

  return (
    <section
      className="animated fadeIn d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="container-fluid pb-9">
        <div className="text-center pb-5">
          <img
            src={STORIER_LOGO}
            alt="Storier Logo"
            style={{ transform: "rotate(1.15deg)", maxWidth: 256 }}
          />
        </div>

        <div style={{ maxWidth: 500 }} className="mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              {emailInput}
            </div>
            <div className="form-group">
              <label
                htmlFor="password"
                className="form-label d-flex align-items-center justify-content-between"
              >
                Password
                <a href={`${window.SITE}/forgotpassword`} className="link-muted">
                  Forgot Password
                </a>
              </label>

              {passwordInput}
            </div>

            <div className="pt-4">
              <button
                type="submit"
                className="btn btn-primary btn-block btn-pill transition-3d-hover"
              >
                Sign In
              </button>
              {/* <button
                type="button"
                className="btn btn-soft-secondary btn-block btn-pill"
                onClick={signInWithApple}
              >
                <i className="fab fa-apple"/> Apple
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-block btn-pill"
                onClick={signInWithFacebook}
              >
                <i className="fab fa-facebook-square"/> Facebook
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-block btn-pill"
                onClick={signInWithGoogle}
              >
                <i className="fab fa-google"/> Google
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
