import React, { useState, useEffect, useContext } from 'react';
import { firestore } from '../firebase';
import { UserContext } from './UserProvider';
import { collectIdsAndDocs } from '../utilities';

const CollectionContext = React.createContext();

const CollectionProvider = props => {
	const user = useContext(UserContext);

	const [collection, setCollection] = useState([]);

	useEffect(() => {
		if (user) {
			const unsubscribe = firestore
				.collection(`users/${user.uid}/purchased`)
				.onSnapshot(snapshot => {
					setCollection(
						snapshot.docs.map(collectIdsAndDocs)
					);
				});

			return () => unsubscribe();
		}
	}, [user]);

	return (
		<CollectionContext.Provider value={collection}>
			{props.children}
		</CollectionContext.Provider>
	);
};

export { CollectionContext, CollectionProvider };