import React from "react";
import Track from "./Track";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableItem from "../Draggable/DraggableItem";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const reorderedList = list.map((item, index) => ({ ...item, order: index }));
  const result = Array.from(reorderedList);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#F7FAFF" : "white",
  padding: 0,
  width: "100%"
});

const TrackList = props => {
  const {
    tracks,
    draggable,
    onReordered,
    playlistId,
  } = props;

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedTracks = reorder(
      tracks,
      result.source.index,
      result.destination.index
    );

    onReordered(reorderedTracks);
  };

  const trackRenders = tracks.map((track, index) => (
    <Track
      key={index}
      {...track}
      playlistId={playlistId}
      index={index}
      draggable={draggable}
    />
  ));

  if (draggable)
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {trackRenders.map((item, index) => (
                <DraggableItem
                  key={`trackList-draggable-${index}`}
                  index={index}
                >
                  {item}
                </DraggableItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  else
    return (
      <div className="w-100">
        <div id="tracklist" className="list-group border-bottom">
          {trackRenders}
        </div>
      </div>
    );
};

export default TrackList;
