import React, { useContext, useEffect } from "react";
import { analytics, firestore } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faFarStar } from "@fortawesome/free-regular-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import { UserContext } from "../../providers/UserProvider";
import { FavoriteSeriesContext } from "../../providers/FavoriteSeriesProvider";

const ButtonFavoriteSeries = ({ seriesId }) => {
  const user = useContext(UserContext);
  const bookmarks = useContext(FavoriteSeriesContext);

  const [isFavorited, setIsFavorited] = React.useState(false);

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {!isFavorited ? "Add to favorites!" : "Remove from favorites?"}
      </Tooltip>
    );
  }

  useEffect(() => {
    if (bookmarks.filter(b => b.id === seriesId).length > 0)
      setIsFavorited(true);
  }, [bookmarks, seriesId]);

  function toggleFavorite() {
    toast.dismiss();
    const bookmarksRef = firestore.collection(
      `users/${user.uid}/favoriteSeries`
    );

    if (!isFavorited) {
      bookmarksRef
        .doc(seriesId)
        .set({
          seriesId,
          dateAdded: new Date()
        })
        .then(() => {
          analytics.logEvent('favorites_series_added', {
            seriesId
          });
          setIsFavorited(true);
          toast("Series added to favorites!");
        })
        .catch(error => {
          console.error("Error setting favorite...", error);
          toast.error(
            "Uh oh! Something happened and we couldn't save your favorite. Try again later."
          );
        });
    } else {
      bookmarksRef
        .doc(seriesId)
        .delete()
        .then(() => {
          analytics.logEvent('favorites_series_removed', {
            seriesId
          });
          setIsFavorited(false);
          toast("Favorite removed!");
        })
        .catch(error => {
          console.error("Error setting bookmark...", error);
          toast.error(
            "Uh oh! Something happened and we couldn't save your favorite. Try again later."
          );
        });
    }
    setIsFavorited(!isFavorited);
  }

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 800, hide: 400 }}
      overlay={renderTooltip}
      className="bg-primary"
    >
      <button
        type="button"
        onClick={toggleFavorite}
        className="btn btn-soft-info btn-pill btn-icon"
      >
        {isFavorited ? (
          <FontAwesomeIcon icon={faStar} />
        ) : (
          <FontAwesomeIcon icon={faFarStar} />
        )}
      </button>
    </OverlayTrigger>
  );
};

export default ButtonFavoriteSeries;
