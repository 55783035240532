import React, { useContext, useState, useEffect } from "react";
import { CuratedListContext } from "../../providers/CuratedListProvider";
import { UserContext } from "../../providers/UserProvider";
import { collectIdsAndDocs } from "../../utilities";
import SeriesCard from "../Series/SeriesCard";
import { ListenHistoryContext } from "../../providers/ListenHistoryProvider";
import { firestore } from "../../firebase";
import TokenBanner from '../Tokens/TokenBanner';

const Home = () => {
  const curatedLists = useContext(CuratedListContext);
  const { listenHistory } = useContext(ListenHistoryContext);
  const [fetchedSeries, setFetchedSeries] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {

    if (!curatedLists || curatedLists.length === 0) {
      return;
    }

    const seriesPromises = curatedLists.map(curatedList => {
      return curatedList.seriesList.map(seriesId => {
        return firestore
          .collection("series")
          .doc(seriesId)
          .get();
      });
    }).flat();

    Promise.all(seriesPromises).then(seriesResults => {
      const filteredSeries = seriesResults
        .filter(result => result.exists)
        .map(collectIdsAndDocs)
        .filter(item => user && item.salesRightsCountries && item.salesRightsCountries.includes(user.private.country))
        .filter(item => user.private.subscription !== 'BASIC' || !item.isPremium);

      setFetchedSeries(filteredSeries);
    });


  }, [curatedLists, user]);

  return (
    <section className="animated fadeIn pt-0">
      <TokenBanner />
      <div className="container-fluid">
        {listenHistory.length ?
          <div className="w-100 space-bottom-1">
            <h1 className="heavy-font text-purple mb-3">Recently Played</h1>
            <div className="row cardWrapper">
              {listenHistory.map(item => {
                if (item.historyType === 'series') {
                  return (
                    <SeriesCard
                      key={"history" + item.id}
                      className="homeCard"
                      {...item}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div> : ''}
        {curatedLists.map(curatedList => {
          const curatedListSeries = curatedList.seriesList
            .map(seriesId => fetchedSeries.find(curSeries => curSeries.id === seriesId))
            .filter(item => item);

          if (curatedListSeries.length < 2) {
            return null;
          }

          return (
            <div className="w-100 space-bottom-1" key={curatedList.label}>
              <h1 className="heavy-font text-purple mb-3">{curatedList.label}</h1>
              <div className="row cardWrapper">
                {curatedListSeries.map(series => {
                  return (
                    <SeriesCard
                      key={series.id}
                      className="homeCard"
                      {...series}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  );
};

export default Home;
