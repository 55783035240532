import React, { useContext } from "react";
import "../../style/VolumeSlider.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeDown, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { PlayerContext } from "../../providers/PlayerProvider";

const VolumeSlider = () => {
  const { volume, changeVolume } = useContext(PlayerContext);

  return (
    <div className="w-100 d-flex justify-content-center align-items-center has-svg">
      <button className="no-style has-svg no-padding text-white" onClick={() => changeVolume(volume - 0.1)}>
        <FontAwesomeIcon icon={faVolumeDown} size="lg" />
      </button>
      <form className="w-75 px-2 pt-1">
        <input
          type="range"
          min="0"
          max="100"
          onChange={e => changeVolume(e.target.value / 100)}
          value={volume * 100}
          className="custom-range"
          style={{ height: 15.1 }}
        />
      </form>
      <button className="no-style has-svg no-padding text-white">
        <FontAwesomeIcon icon={faVolumeUp} size="lg" onClick={() => changeVolume(volume + 0.1)} />
      </button>
    </div>
  );
};

export default VolumeSlider;
