import React, { useState, createContext, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs, uniqueItemsByKey } from "../utilities";
import { UserContext } from "./UserProvider";

const ListenHistoryContext = createContext({
  listenHistory: []
});

const ListenHistoryProvider = props => {
  const user = useContext(UserContext);

  const [{ listenHistory }, setListenHistory] = useState({
    listenHistory: []
  });

  useEffect(() => {
    const getData = async () => {
      const listenHistoryRaw = (await firestore
        .collection("users")
        .doc(user.uid)
        .collection("listenHistory")
        .orderBy("addedAt", "desc")
        .limit(20)
        .get())
        .docs;

      const listenHistoryDocs = uniqueItemsByKey(listenHistoryRaw.map(collectIdsAndDocs), "collectionId").slice(0,6);

      const listenHistoryPromises = listenHistoryDocs.map(async item => {
        const { collectionId, collection } = item;

        if (collectionId === user.favoriteTracksId) {
          return Promise.resolve({
            historyType: 'favoriteTracks'
          });
        } else {
          let collectionDoc = (await firestore
            .collection(collection)
            .doc(collectionId)
            .get())
            .data();

          return Promise.resolve({
            historyType: collection,
            id: collectionId,
            ...collectionDoc
          });
        }
      });

      let newListenHistory = await Promise.all(listenHistoryPromises);

      setListenHistory({ listenHistory: newListenHistory });
    };

    if (!user) {
      return;
    }

    getData();
  }, [user]);

  return (
    <ListenHistoryContext.Provider value={{ listenHistory }}>
      {props.children}
    </ListenHistoryContext.Provider>
  );
};

export { ListenHistoryProvider, ListenHistoryContext };
