import React from "react";
import { FavoriteSeriesContext } from "../../providers/FavoriteSeriesProvider";
import FavoriteSeriesCard from "./FavoriteSeriesCard";

import SeriesIcon from '../../assets/icons/SeriesIcon.png';

const FavoriteSeries = () => {
  const favorites = React.useContext(FavoriteSeriesContext);

  return (
    <div className="container-fluid animated fadeIn">
      <header className="space-top-1 space-bottom-2">
        <div className="text-center">
          <div className="mb-5">
            <img
              src={SeriesIcon}
              alt="My favorite series!"
              style={{ height: `6rem` }}
            />
          </div>
          <h1 className="display-4 heavy-font">Favorite Series</h1>

          <p className="lead text-muted mt-3">Collections you liked most.</p>
        </div>
      </header>

      <article>
        <div className="row compact-row">
          {favorites.map(fave => (
            <FavoriteSeriesCard key={fave.id} {...fave} />
          ))}
        </div>
      </article>
    </div>
  );
};

export default FavoriteSeries;
