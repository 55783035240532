import React from "react";
import { STORIER_LOGO } from "../../constants/Metadata";
import { signOut } from "../../firebase";


const SignUp = () => {

  return (
    <section
      className="animated fadeIn d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="container-fluid pb-9">
        <div className="text-center pb-5">
          <img
            src={STORIER_LOGO}
            alt="Storier Logo"
            style={{ transform: "rotate(1.15deg)", maxWidth: 256 }}
          />
        </div>

        <div style={{ maxWidth: 500 }} className="mx-auto">
          We're sorry, we can't find an active subscription for you!  Please subscribe to access hundreds of hours of award-winning podcasts, audio shows, music, aduiobooks, and more.

          <div className="pt-4">
            <a
              className="btn btn-primary btn-block btn-pill transition-3d-hover text-white"
              href={`${window.SITE}/subscribe`}
            >
              Subscribe Now
            </a>
          </div>
          <div className="pt-4">
            <button
              className="btn btn-secondary btn-block btn-pill transition-3d-hover text-white"
              onClick={signOut}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
