import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faStepBackward,
  faStepForward,
  faUndoAlt,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PlayerControlButton } from "../PlayerBar/PlayerControlButton";
import { PlayerContext } from "../../providers/PlayerProvider";

const MobilePlayerControls = () => {
  const {
    activeTrack,
    isPlaying,
    togglePlay,
    next,
    prev,
    jogBackward,
    jogForward,
  } = useContext(PlayerContext);

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(!activeTrack);
  }, [activeTrack]);

  return (
    <div className="d-flex align-items-center justify-content-around px-3 pb-7">
      <PlayerControlButton
        label="Back 30 seconds"
        handleClick={jogBackward}
        disabled={disable}
      >
        <FontAwesomeIcon icon={faUndoAlt} size="2x" rotate="270" />
      </PlayerControlButton>

      <PlayerControlButton
        label="Step Backward"
        handleClick={prev}
        disabled={disable}
      >
        <FontAwesomeIcon icon={faStepBackward} size="2x" />
      </PlayerControlButton>

      <PlayerControlButton
        label="Play/Pause"
        handleClick={togglePlay}
        disabled={disable}
      >
        <FontAwesomeIcon icon={isPlaying ? faPauseCircle : faPlayCircle} size="4x" />
      </PlayerControlButton>

      <PlayerControlButton
        label="Step Forward"
        handleClick={next}
        disabled={disable}
      >
        <FontAwesomeIcon icon={faStepForward} size="2x" />
      </PlayerControlButton>

      <PlayerControlButton
        label="Forward 30 seconds"
        handleClick={jogForward}
        disabled={disable}
      >
        <FontAwesomeIcon icon={faRedoAlt} size="2x" rotate="90" />
      </PlayerControlButton>
    </div>
  );
};

export default MobilePlayerControls;
