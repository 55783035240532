import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import StorierAnalytics from "@storier/analytics/dist"

firebase.initializeApp(window.FIREBASE_CONFIG_JSON);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
export const functions = firebase.functions();
export const fieldValue = firebase.firestore.FieldValue;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  functions.useEmulator("localhost", 5001);
}

export const analyticsFactory = StorierAnalytics(firestore);

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('email');
export const signInWithGoogle = () => {
  auth.signInWithPopup(googleProvider)
    .then(async userCredential => {
      await createUserProfileDocument({
        ...userCredential.user,
        email: userCredential.additionalUserInfo.profile.email
      }, {
        preferences: {
          allowPush: true,
          receiveNews: false
        }
      });

      await functions.httpsCallable('updateCountry')();
    });
};

const fbProvider = new firebase.auth.FacebookAuthProvider();
fbProvider.addScope('email');
export const signInWithFacebook = () => {
  auth.signInWithPopup(fbProvider)
    .then(async userCredential => {
      await createUserProfileDocument({
        ...userCredential.user,
        email: userCredential.additionalUserInfo.profile.email
      }, {
        preferences: {
          allowPush: true,
          receiveNews: false
        }
      });

      await functions.httpsCallable('updateCountry')();
    });
};

const appleProvider = new firebase.auth.OAuthProvider('apple.com');
appleProvider.addScope('email');
export const signInWithApple = () => {
  auth.signInWithPopup(appleProvider)
    .then(async userCredential => {
      await createUserProfileDocument({
        ...userCredential.user,
        email: userCredential.additionalUserInfo.profile.email
      }, {
        preferences: {
          allowPush: true,
          receiveNews: false
        }
      });

      await functions.httpsCallable('updateCountry')();
    });
};

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) return;

  // Get a reference to the location in the Firestore where the user
  // document may or may not exist.
  const userRef = firestore.doc(`users/${user.uid}`);

  // Go and fetch a document from that location.
  const snapshot = await userRef.get();

  // If there isn't a document for that user. Let's use information
  // that we got from either Google or our sign up form.
  if (!snapshot.exists) {
    const { displayName, email, photoURL } = user;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user", console.error);
    }
  }

  // Get the document and return it, since that's what we're
  // likely to want to do next.
  return getUserDocument(user.uid);
};

export const signInWithEmail = (email, password) => {
  auth.signInWithEmailAndPassword(email, password)
    .then(UserCredential => {
      if (UserCredential) {
        functions.httpsCallable('updateCountry')()
      }
    })
    .catch(error => {
      if (error.code === "auth/wrong-password") {
        alert("Wrong password.");
      } else {
        alert(error.message);
      }
    });
};

export const signOut = () => {
  auth.signOut().catch(error => {
    console.error(error);
  });
};

export const getUserDocument = uid => {
  if (!uid) return null;
  try {
    return firestore.collection("users").doc(uid);
  } catch (error) {
    console.error("Error fetching user", error.message);
  }
};

export const increment = firebase.firestore.FieldValue.increment(1);
export const decrement = firebase.firestore.FieldValue.increment(-1);

export default firebase;
