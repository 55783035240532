import React, { useEffect, useState } from "react";
import { storage } from "../firebase";

import '../style/CoverArt.scss';
import { TYPES } from "../constants/Metadata";
import Spinner from "./Spinner";

const getIcon = (seriesType) => {
  const seriesTypeIcon = (TYPES.find(type => type.id === seriesType) || {}).iconClass;
  return <i className={seriesTypeIcon} style={{width: 28, fontSize: 25, textShadow: '1px 1px 4px rgba(0, 0, 0, .5)', color: 'white'}} />;
};

const CoverArt = ({ url, alt, type, large }) => {

  const [src, setSrc] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (url) {
        setSrc(await storage
          .ref(url)
          .getDownloadURL());
      } else {
        setSrc(window.ASSETS + "/app-ui/gradient-book-icon.png");
      }
    };

    getData();
  }, [url]);

  const getTitleDisplay = () => {
    if (!url) {
      return (
        <div
          className={`w-100 p-2 text-center text-white d-block`}
          style={{
            position: 'absolute',
            top: large ? '70%' : '61%',
            zIndex: 1
          }}
        >
          {alt}
        </div>
      );
    }
  };

  const getTypeDisplay = () => {
    if (type) {
      return (
        <div style={{ position: 'absolute', right: 10, bottom: 5, zIndex: 1 }}>
          {getIcon(type)}
        </div>
      )
    }
  };


  return (
    <div className='coverArt'>
      {getTypeDisplay()}
      {getTitleDisplay()}
      <Spinner fetching={!src} adjust={true}>
        <img src={src} alt={alt} style={{zIndex: 0}}/>
      </Spinner>
    </div>
  );
};

export default CoverArt;
