import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import UserProvider from "./providers/UserProvider";

import SignIn from "./components/views/SignIn";
import SignUp from "./components/views/SignUp";
import Authentication from "./components/Authentication";

import "./style/main.scss";
import { PlaylistsProvider } from "./providers/PlaylistsProvider";
import { FavoriteTracksProvider } from "./providers/FavoriteTracksProvider";
import { FavoriteSeriesProvider } from "./providers/FavoriteSeriesProvider";
import { MobilePlayerProvider } from "./providers/MobilePlayerContext";
import { CollectionProvider } from "./providers/CollectionProvider";
import FullScreenPlayer from "./components/MobilePlayer/FullScreenPlayer";
import TopBar from "./components/layout/Topbar";
import Wrapper from "./components/layout/Wrapper";
import ContentPanel from "./components/layout/ContentPanel";
import Home from "./components/views/Home";
import Search from "./components/views/Search";
import MyLibrary from "./components/views/MyLibrary";
import Series from "./components/Series/Series";
import Playlist from "./components/Playlists/Playlist";
import FavoriteSeries from "./components/Favorites/FavoriteSeries";
import StoryCollection from "./components/Collection/StoryCollection";
import Query from "./components/views/Query";
import PageNotFound from "./components/views/PageNotFound";
import PlayerBar from "./components/PlayerBar/PlayerBar";
import { PlayerProvider } from "./providers/PlayerProvider";
import { ListenHistoryProvider } from "./providers/ListenHistoryProvider";
import "./style/Card.scss";
import { CuratedListProvider } from "./providers/CuratedListProvider";
import PromiseSpinner from "./components/PromiseSpinner";

import './App.scss';

function App() {
  return (
    <UserProvider>
      <PromiseSpinner />
      <Router>
        <Authentication />
        <Switch>
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />

          <CuratedListProvider>
            <ListenHistoryProvider>
              <PlayerProvider>
                <PlaylistsProvider>
                  <FavoriteTracksProvider>
                    <FavoriteSeriesProvider>
                      <CollectionProvider>
                        <MobilePlayerProvider>
                          <FullScreenPlayer />
                          <TopBar />
                          <Wrapper>
                            <ContentPanel>
                              <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/search" component={Search} />
                                <Route path="/mylibrary" component={MyLibrary} />
                                <Route
                                  path="/series/:seriesId"
                                  component={Series}
                                />
                                <Route
                                  path="/playlist/:playlistId"
                                  component={Playlist}
                                />
                                <Route
                                  path="/favorite-tracks"
                                  component={Playlist}
                                />
                                <Route path="/favorite-series" component={FavoriteSeries} />
                                <Route path="/story-collection" component={StoryCollection} />
                                <Route
                                  path="/browse/:collection/:category"
                                  render={props => <Query {...props} />}
                                />
                                <Route component={PageNotFound} />
                              </Switch>
                            </ContentPanel>
                          </Wrapper>
                          <PlayerBar />
                        </MobilePlayerProvider>
                      </CollectionProvider>
                    </FavoriteSeriesProvider>
                  </FavoriteTracksProvider>
                </PlaylistsProvider>
              </PlayerProvider>
            </ListenHistoryProvider>
          </CuratedListProvider>
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
