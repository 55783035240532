import React from "react";

const PageHeader = props => {
  const { title, svg } = props;
  return (
    <header className="space-1 jumbotron">
      <div className="d-flex align-items-center">
        {svg && (
          <img
            src={svg}
            alt={title}
            style={{ width: `4.8rem` }}
            className="mr-3"
          />
        )}
        <h1 className="heavy-font display-4 mb-0" style={{ opacity: 0.8 }}>
          {title}
        </h1>
      </div>
    </header>
  );
};

export default PageHeader;
