import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { PlayerContext } from "../providers/PlayerProvider";

const ButtonPlayNow = props => {
  const { id, collection } = props;

  const { playTracks, activeListId, togglePlay, isPlaying } = useContext(PlayerContext);

  const playingThisList = activeListId === id;

  const handleClick = () => {
    if (playingThisList) {
      togglePlay();
    } else {
      playTracks(id, collection);
    }
  };

  const icon = isPlaying && playingThisList ? faPause : faPlay;
  const text = isPlaying && playingThisList ? 'Pause' : 'Play All';

  return (
    <button
      type="button"
      className="btn btn-main mr-2"
      onClick={handleClick}
      style={{ width: 128 }}
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {text}
    </button>
  );
};

export default ButtonPlayNow;
