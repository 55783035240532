import React from "react";
import { Link } from "react-router-dom";
import CoverArt from "../CoverArt";

import StorierToken from "../../assets/icons/StorierToken.png";

const SeriesCard = props => {
  const {
    id,
    type,
    title,
    coverUrl,
    className
  } = props;

  return (
    <div
      className={`animated fadeIn col-6 col-sm-4 col-md-4 col-lg-3 col-xl-3 ${className}`}
    >
      <Link to={`/series/${id}`}>
        <CoverArt url={coverUrl} alt={title} type={type} />
        {
          props.isPremium &&
          <img
            src={StorierToken}
            alt="token"
            style={{
              position: 'absolute',
              left: 25,
              bottom: 10,
              width: 50,
            }}
          />
        }
      </Link>
    </div>
  );
};

export default SeriesCard;
