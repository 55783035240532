import React, { useContext } from 'react';

import { UserContext } from '../../providers/UserProvider';
import { getDaysTillTokenText, getMonthlyTokenAmount, getDaysTillToken } from '../../utilities';
import TokenIcon from './TokenIcon';

import './TokenBanner.scss';

const TokenBanner = () => {
	const user = useContext(UserContext);

	return (
		(
			user && user.private && user.private.tokens &&
			(user.private.subscription === 'EXTRA' || user.private.subscription === 'SUPER')
		) ?
			<div id="token-banner">
				<div className="token-banner-inner">
					<TokenIcon
						value={user.private.tokens}
						width={50}
					/>
					<div className="ml-2 mb-2">
						<div>
							{`${getDaysTillTokenText(user)} until your next ${getMonthlyTokenAmount(user)} Storier Tokens`}
						</div>
						<div className="progress-outer">
							<div className="progress-inner" style={{width: `${ ((30-getDaysTillToken(user))/30) * 100 }%`}}/>
						</div>
					</div>
				</div>
			</div>
			:
			null
	)
};

export default TokenBanner;