import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// export const toastNotify = str => {
//   return toast(str);
// };

// export const toastError = str => {
//   return toast.error(str);
// };

// export const toastSuccess = str => {
//   return toast.success(str);
// };

// export const toastInfo = str => {
//   return toast.info(str);
// };

// export const toastWarn = str => {
//   return toast.warn(str);
// };

// export const toastClear = () => {
//   return toast.dismiss();
// };

const ToastDefault = () => (
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      closeOnClick
      // hideProgressBar={true}
      className="buttery-toast"
    />
);

export default ToastDefault;
