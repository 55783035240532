import React, { useState, createContext, useEffect } from "react";
import { firestore } from "../firebase";
import { collectIdsAndDocs } from "../utilities";

const CuratedListContext = createContext([]);

const CuratedListProvider = props => {
  const [curatedLists, setCuratedLists] = useState([]);

  useEffect(() => {
    firestore
      .collection("curatedLists")
      .get()
      .then(results => {
        const fetchedCuratedLists = results.docs.map(collectIdsAndDocs);
        setCuratedLists(fetchedCuratedLists.sort((a,b) => a.order - b.order));
      });
  }, []);

  return (
    <CuratedListContext.Provider value={curatedLists}>
      {props.children}
    </CuratedListContext.Provider>
  );
};

export { CuratedListProvider, CuratedListContext };
