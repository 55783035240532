import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth, getUserDocument } from "../firebase";

const Authentication = () => {
  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userDoc = (await getUserDocument(userAuth.uid).get()).data();

        if (!userDoc || !userDoc.private || !userDoc.private.subscriptionActive) {
          history.push('/signup');
        }

        if (history.location.pathname === '/signin')
          history.push('/');
      } else {
        history.push('/signin');
      }
    });
  }, [history]);

  return null;
};

export default Authentication;
