import React from "react";
import { useHistory } from "react-router-dom";

const ButtonGoBack = props => {
  const { className, children } = props;
  let history = useHistory();

  return (
    <button
      title="Navigate Back"
      onClick={() => history.goBack()}
      type="button"
      className={className}
    >
      {children}
    </button>
  );
};

export default ButtonGoBack;
